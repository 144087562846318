import { useEffect, useState } from "react";
import config from './../config.js'
import { PublicKey, Transaction, TransactionInstruction } from "@solana/web3.js";
import { Metaplex } from "@metaplex-foundation/js";
import { enqueueSnackbar } from "notistack";
import base58 from "bs58";
export const updateProject = async (setProject, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-project`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setProject(reqJson.data)
        else setProject()
    } catch (err) {
        console.log(err)
        setProject()
    }
}


export const updateAreas = async (setAreas, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-areas`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setAreas(reqJson.data)
        else setAreas()
    } catch (err) {
        console.log(err)
        setAreas()
    }
}

export const updateWalletAccount = async (setWalletAccount, publicKey, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-owned-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setWalletAccount(reqJson.data?.nfts)
        else setWalletAccount()
    } catch (err) {
        console.log(err)
        setWalletAccount()
    }
}

export const updateLevels = async (setLevels, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-levels`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setLevels(reqJson.data)
        else setLevels()
    } catch (err) {
        console.log(err)
        setLevels()
    }
}


export const updateNfts = async (setNfts, publicKey, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-owned-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setNfts(reqJson.data?.nfts)
        else setNfts()
    } catch (err) {
        console.log(err)
        setNfts()
    }
}

export const updateMissions = async (setMissions, publicKey, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-missions`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setMissions(reqJson.data)
        else setMissions()
    } catch (err) {
        console.log(err)
        setMissions()
    }
}


export const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
}



export const inWords = (numberInput) => {

    let oneToTwenty = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ',
        'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    let tenth = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if (numberInput < 20) return oneToTwenty[numberInput]
    if (numberInput.toString().length > 7) return numberInput
    let num = ('0000000' + numberInput).slice(-7).match(/^(\d{1})(\d{1})(\d{2})(\d{1})(\d{2})$/)
    if (!num) return

    let outputText = num[1] !== 0 ? (oneToTwenty[Number(num[1])] || `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + ' million ' : ''

    outputText += num[2] !== 0 ? (oneToTwenty[Number(num[2])] || `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + 'hundred ' : ''
    outputText += num[3] !== 0 ? (oneToTwenty[Number(num[3])] || `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + ' thousand ' : ''
    outputText += num[4] !== 0 ? (oneToTwenty[Number(num[4])] || `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + 'hundred ' : ''
    outputText += num[5] !== 0 ? (oneToTwenty[Number(num[5])] || `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]} `) : ''

    return outputText
}

export const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export const getTokenInfo = async (tokenAddress, connection) => {
    try {
        const mint = new PublicKey(tokenAddress);
        const tokenInfo = await connection.getTokenLargestAccounts(mint, 'finalized');
        if (tokenInfo && tokenInfo.value.length > 0) {
            const metaplex = new Metaplex(connection)
            let tokenMetadata;
            try {
                let tokenData = await metaplex.nfts().findByToken({ token: tokenInfo.value[0].address });
                tokenMetadata = await loadSplTokenMetadata(tokenData)
                if (tokenData.jsonLoaded) tokenMetadata = tokenData.json
            } catch (e) {
                console.log(e)
            }
            if (!tokenMetadata) tokenMetadata = { image: '', name: 'SPL-TOKEN', symbol: `${tokenAddress.slice(0, 3)}...${tokenAddress.slice(-3)}` }
            return {
                status: true,
                message: undefined,
                data: {
                    tokenName: tokenMetadata.name,
                    tokenSymbol: tokenMetadata.symbol,
                    tokenDecimals: Math.pow(10, tokenInfo.value[0].decimals),
                }
            }
        } else {
            return {
                status: false,
                message: 'No token account found for the given address.',
                data: {
                }
            }
        }
    } catch (error) {
        return {
            status: false,
            message: `ERROR: ${error.message || error.msg || ''}`,
            data: {
            }
        }
    }
}

export const loadSplTokenMetadata = async (tokenMetadata) => {
    let response;
    for (var i = 0; i <= 5; i++) {
        try {
            const res = await fetch(tokenMetadata.uri)
            const tokenUriData = await res.json()
            if (tokenUriData) {
                response = tokenUriData
                break
            }
        } catch (err) {
            continue
        }
    }
    return response
}

export const verifyWallet = async (signMessage, setDisplayModal, walletAddress, projectId, ledger, sendTransaction, connection, setDisableSignBtn) => {
    try {
        setDisableSignBtn(true)
        const nonceReq = await fetch(`${config.apiUrl}/get-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId
            })
        })
        const nonce = await nonceReq.json()
        if (!nonce.status) return enqueueSnackbar({ message: nonce.message, variant: 'error' })
        else {
            let signature;
            if (!ledger) {
                signature = await signMessage(new TextEncoder().encode(`${nonce.data.nonce}`))
                signature = base58.encode(signature)
            } else {
                let tx = new Transaction();
                await tx.add(
                    new TransactionInstruction({
                        keys: [{ pubkey: walletAddress, isSigner: true, isWritable: true }],
                        data: Buffer.from(nonce.data.nonce, "utf-8"),
                        programId: new PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
                    })
                )
                const blockHash = await connection.getLatestBlockhash();
                tx.feePayer = walletAddress;
                tx.recentBlockhash = blockHash.blockhash;

                signature = await sendTransaction(tx, connection)
                await connection.confirmTransaction({
                    blockhash: blockHash.blockhash,
                    lastValidBlockHeight: blockHash.lastValidBlockHeight,
                    signature
                });
                let checked = false
                for (var i = 0; i <= 10; i++) {
                    const response = await checkVerifiedWallet(walletAddress, projectId, nonce.data.id, signature)
                    if (response.data.nonce) {
                        await sleep(10000)
                        continue
                    } if (!response.status) {
                        await sleep(10000)
                        continue
                    } else {
                        checked = true
                        break;
                    }
                }
                if (!checked) {
                    setDisableSignBtn(false)
                    return enqueueSnackbar({ message: 'Couldn\'t verify your wallet', variant: 'error' })
                }
            }
            if (!signature) {
                setDisableSignBtn(false)
                return enqueueSnackbar({ message: 'An Error occured while signing', variant: 'error' })
            }
            else {
                localStorage.setItem('nonceSignature', signature)
                localStorage.setItem('nonceId', nonce.data.id)
                localStorage.setItem('nonceExpiry', Date.now() + 7 * 24 * 60 * 60 * 1000)
                setDisplayModal(false)
                setDisableSignBtn(false)
                return enqueueSnackbar({ message: 'Successfully signed in', variant: 'success' })
            }
        }
    } catch (err) {
        setDisableSignBtn(false)
        console.log(err)
        enqueueSnackbar({ message: `ERROR: ${err.message || `An unknown error occured`}`, variant: 'error' })
    }
}


export const checkVerifiedWallet = async (walletAddress, projectId, nonceId, signature) => {
    try {
        const nonceReq = await fetch(`${config.apiUrl}/verify-nonce`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                walletAddress: walletAddress,
                projectId: projectId,
                nonceId: nonceId,
                signature: signature
            })
        })
        const nonce = await nonceReq.json()
        return nonce
    } catch {
        return {
            status: false,
            message: 'An unknown error occured',
            data: {}
        }
    }
}

