import { enqueueSnackbar } from "notistack"
import config from "../config"
import { checkVerifiedWallet, getTokenInfo } from "./utility"


export const createProject = async (name, logo, expGears, hashlist, ownerAddress, setDisableBtn, projectId, publicKey, setDisplaySignModal, connection) => {
    try {
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')
        let errorCheck;
        let updatedExpGears = await Promise.all(expGears.map(async x => {
            if (x.data.prices?.length) {
                x.data.prices = await Promise.all(x.data.prices.map(async y => {
                    const tokenInfo = await getTokenInfo(y.data.tokenAddress, connection, publicKey)
                    y.data.decimals = tokenInfo.data.tokenDecimals
                    y.data.image = tokenInfo.data.image
                    y.data.symbol = tokenInfo.data.tokenSymbol
                    if (!tokenInfo.status) errorCheck = tokenInfo
                    return y
                }))
            }
            return x
        }))
        if (errorCheck) {
            setDisableBtn(false)
            return enqueueSnackbar({ message: errorCheck.message, variant: 'error' })
        }
        const req = await fetch(`${config.apiUrl}/create-project`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                name,
                logo,
                hashlist,
                ownerAddress,
                expGears: updatedExpGears,
                nonceId,
                nonceSignature,
            })
        })
        const reqJson = await req.json()
        if (reqJson.status) {
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setDisableBtn(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || `An unknown error occured`}`, variant: 'error' })
        console.log(err)
    }
}

export const launchExpeditionGetUsedNfts = async (projectId, publicKey, setUsedNfts) => {
    try {
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const req = await fetch(`${config.apiUrl}/launch-expedition-get-used-nfts`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                nonceId,
                nonceSignature
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setUsedNfts(reqJson.data)
        else setUsedNfts()
    } catch (err) {
        console.log(err)
        setUsedNfts()
    }
}
export const launchExpedition = async (name, description, minLevel, expTime, expFees, expKits, spotsAvailable,tokenAddress, tokenAmount, maxExpPerWallet, reoccuring, occurrences, rewards, setDisableBtn, projectId, publicKey, setDisplaySignModal, startingAt) => {
    try {
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const req = await fetch(`${config.apiUrl}/launch-expedition`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                name,
                description,
                tokenAddress,
                tokenAmount,
                minLevel,
                expTime,
                expFees,
                expKits,
                spotsAvailable,
                maxExpPerWallet,
                reoccuring,
                occurrences,
                rewards,
                nonceId,
                nonceSignature,
                startingAt
            })
        })
        const reqJson = await req.json()
        if (reqJson.status) {
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setDisableBtn(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || `An unknown error occured`}`, variant: 'error' })
        console.log(err)
    }
}


export const deleteExpedition = async (setDisableBtn, projectId, publicKey, setDisplaySignModal, expeditionId) => {
    try {
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const req = await fetch(`${config.apiUrl}/delete-expedition`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                expeditionId,
                nonceId,
                nonceSignature,
            })
        })
        const reqJson = await req.json()
        if (reqJson.status) {
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setDisableBtn(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        setDisableBtn(false)
        enqueueSnackbar({ message: `ERROR: ${err.message || `An unknown error occured`}`, variant: 'error' })
        console.log(err)
    }
}
