import React, { useContext, useEffect } from 'react'
import { MainContext } from '../../App'
import { Link } from 'react-router-dom'
import { useCheckMobileScreen } from '../../javascript/utility'
import lockIcon from './../../assets/img/utility/icons/lock.svg'
export default function AreasList() {
    const { project, navbarTitle, areas, nfts } = useContext(MainContext)
    const [, setNavbarTitle] = navbarTitle
    const isMobile = useCheckMobileScreen()
    useEffect(() => {
        setNavbarTitle('Fortify + Assemble')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='home__content-wrapper' style={{ margin: isMobile ? '0.2rem 1rem' : '0.2rem 1rem', backgroundImage: `url(${project.images.fortifyPageBackground})` }}>
            <div className='home__content' style={{ padding: isMobile ? '1rem 0.5rem' : '1rem 2rem' }}>
                <div className='utility__info-box__yellow utility__info-box__cyan'>
                    <div className='home__anchor-container'>
                        <div>
                            Train your Zalez Beachers to reach new ranks! Each area brings new levels, rewards and gets one step closer to returning Zalez Island to it's former paradise
                        </div>
                    </div>
                </div>
                {areas.sort((a, b) => a.createdAt - b.createdAt).map((x, i) => {
                    const check = checkLockedArea(x, nfts)
                    return (
                        <Link key={i} className={`utility__info-box__yellow ${!check ? 'utility__info-box__yellow-locked' : ''}`} to={`${check ? x.id : ''}`} style={{ position: 'relative' }}>
                            <img src={lockIcon} alt="Lock" style={{ height: '60%', display: !check && !isMobile ? 'block' : 'none', position: 'absolute', left: '1.5%' }} />
                            <div className='home__anchor-container'>
                                <div className='utility__info-box__yellow-title font-marker'>
                                    {x.label}
                                </div>
                                <div>
                                    {x.description}
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

const checkLockedArea = (x, nfts) => {
    try {
        if (!x.requirement || !x.requirement.length) return true
        else {
            let check = true
            x.requirement.forEach(y => {
                if (y.type === 'prestige_level') {
                    if (nfts) {
                        if (!nfts.length || !nfts.find(z => z.level && z.level >= y.data.level)) {
                            check = false
                        }
                    }
                }
            })
            return check
        }
    } catch {
        return false
    }
}