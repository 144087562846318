import React, { useContext } from 'react'
import { MainContext } from '../../App'
import { useCheckMobileScreen } from '../../javascript/utility.js'
import { BrowserView, MobileView } from './DeviceDetect.js'
import ConnectButton from './ConnectButton'
import MagicEdenImg from './../../assets/img/utility/socials/magiceden.png'
import DiscordImg from './../../assets/img/utility/socials/discord.svg'
import TwitterImg from './../../assets/img/utility/socials/twitter.svg'
import HamburgerIcon from './../../assets/img/utility/icons/hamburger.svg'
import Logo from './../../assets/img/logo.png'

export default function Navbar({ openedSidebarState }) {
    const { project, navbarTitle } = useContext(MainContext)
    const isMobile = useCheckMobileScreen()
    const [title] = navbarTitle
    return (
        <div className='utility__navbar'>
            <BrowserView>
                <div className='utility__navbar-logo' style={{ maxWidth: isMobile ? '80vw' : '30vw' }} >
                    <img src={Logo} alt={project.name} style={{ width: '100%' , borderRadius: '100px'}} />
                </div>
            </BrowserView>

            <MobileView>
                <div className='utility__navbar-logo' style={{ maxWidth: isMobile ? '80vw' : '30vw', width: '4rem'}} >
                    <img src={Logo} alt={project.name} style={{ width: '100%' , borderRadius: '100px' }} />
                </div>
            </MobileView>

            <BrowserView>
                <div className='font-marker utility__navbar-centered-text'>
                    {title}
                </div>
            </BrowserView>
            <div style={{ display: 'flex', flexFlow: isMobile ? 'row wrap' : 'column', alignItems: 'center', justifyContent: 'center' }}>
                <ConnectButton />
                <MobileView>
                    {openedSidebarState && (
                        <div style={{ width: '50px', maxWidth: '30vw' }}>
                            <div className='utility__navbar-logo utility__sidebar-btn utility__sidebar-btn-yellow' style={{ maxWidth: '30vw', margin: 'auto', width: '100%', padding: '0px', borderRadius: '10px' }} onClick={() => openedSidebarState[1](!openedSidebarState[0])}>
                                <img src={HamburgerIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                            </div>
                        </div>
                    )}
                </MobileView>
                <BrowserView>
                    <Socials />
                </BrowserView>
            </div>
        </div >
    )
}


const Socials = () => {
    const socials = [
        { name: 'Discord', image: DiscordImg, link: 'https://discord.gg/V3hHAnwhbb' },
        { name: 'Twitter', image: TwitterImg, link: 'https://twitter.com/zalez_ale' },
        { name: 'MagicEden', image: MagicEdenImg, link: 'https://magiceden.io/marketplace/zalez_evo' },
    ]
    return (
        <div className='flex flex-row flex-wrap justify-center align-center' style={{ gap: '0.5rem', padding: '0.3rem' }}>
            {socials.map((x, i) => {
                return (
                    <a href={x.link} key={i} target='_blank' rel="noreferrer">
                        <div className='utility__navbar-social' >
                            <img src={x.image} alt={x.name} />
                        </div>
                    </a>
                )
            })}
        </div>
    )
}