import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../App";
import { useWallet } from "@solana/wallet-adapter-react";
import Preloader from "../utility/Preloader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button, Grid } from "@mui/material";
import { ImCross } from "react-icons/im";
import { updateColors } from "../../javascript/fortify";

export const NFTSelect = ({ setChoosenNfts, choosenNfts }) => {
    const { nfts, missions, project, refresh, signModalState } = useContext(MainContext)
    const { publicKey } = useWallet()
    const [nftColors, setNftColors] = useState({})
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [, setDisplaySignModal] = signModalState
    
    return (
        <div className='fortify__select-nft__container' style={{ background: 'none', border: 'none', height: 'unset' }}>
            <div className='font-marker' style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                Select your Zalez beachers
            </div>
            <div className='fortify__select-nfts-wrapper'>
                {!publicKey ? 'You need to connect your wallet' : !nfts ? <Preloader /> : !nfts.filter(y => missions && !missions.find(mission => mission.nfts.includes(y.mintAddress.toString()))).length ? `No NFT to choose` : (
                    <div className='flex flex-row flex-wrap justify-center' style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                        <Grid container sx={{
                            rowGap: '1.2rem',
                            height: 'fit-content'
                        }} columns={{ xs: 9, sm: 16 }}>
                            {nfts.filter(y => missions && !missions.find(mission => mission.nfts.includes(y.mintAddress.toString()))).sort((a, b) => b.exp - a.exp).map((x, i) => {
                                return (
                                    <Grid item xs={3} sm={4} key={i} style={{ width: '100px', height: '100px', aspectRatio: '1 / 1' }}>
                                        <NFTToSelect setNftColors={setNftColors} i={i} setChoosenNfts={setChoosenNfts} x={x} choosenNfts={choosenNfts} />
                                    </Grid>
                                )
                            })
                            }
                        </Grid>

                    </div>)
                }
            </div>
            {publicKey && (<Button disabled={disabledBtn} className='fortify__green-btn' onClick={() => updateColors(publicKey, project.projectId, refresh, nftColors, setNftColors, disabledBtn, setDisabledBtn, setDisplaySignModal)}>
                Update Colours
            </Button>)}

        </div>
    );
}

const NFTToSelect = ({ x, i, setChoosenNfts, choosenNft, number, setNftColors, choosenNfts }) => {
    const [colour, setColour] = useState(x.color)
    useEffect(() => {
        setNftColors((prev) => {
            let newNftColor = JSON.parse(JSON.stringify(prev))
            newNftColor[x.mintAddress] = colour
            return newNftColor
        })
    }, [colour])
    return (
        <div className='fortify__select-nfts__nft-container' style={{ backgroundColor: colour || 'transparent', maxWidth: '100%', transform: choosenNfts.find((z) => z && z.mintAddress === x.mintAddress) ? 'scale(0.9)' : "" }}>
            <div className='fortify__select-nfts__nft-exp'>
                <div>
                    EXP: {Math.floor(x.exp || 0)}
                </div>
                <div>
                    Level: {x.level || 0}
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                <input type="color" style={{ height: '15px', width: '30px' }} value={colour} onChange={(event) => setColour(event.target.value)} />
            </div>
            <LazyLoadImage src={x.image} key={i} style={{ height: 'auto', borderColor: choosenNft?.mintAddress === x?.mintAddress ? '#00ffff' : 'transparent' }} onClick={() => {
                setChoosenNfts((prev) => {

                    let newPrev = JSON.parse(JSON.stringify([...prev]))
                    if (newPrev.find((z, i) => z && z.mintAddress === x.mintAddress)) {
                        newPrev[newPrev.indexOf(newPrev.find(z => z && z.mintAddress === x.mintAddress))] = undefined
                    } else {
                        newPrev.push(x)
                    }
                    newPrev = newPrev.filter(x => x)
                    return newPrev
                })
            }} alt={x.name} className='fortify__select-nfts__nft' />
        </div>)

}

