import React from 'react'
import { useCheckMobileScreen } from '../../javascript/utility'

export function BrowserView({ children }) {
    return (
        <div style={useCheckMobileScreen() ? { display: 'none' } : {}}>
            {children}
        </div>
    )
}

export function MobileView({ children }) {
    return (
        <div style={!useCheckMobileScreen() ? { display: 'none' } : {}}>
            {children}
        </div>
    )
}
