import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../App";
import { useWallet } from "@solana/wallet-adapter-react";
import Preloader from "../utility/Preloader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Grid } from "@mui/material";

export const NFTSelect = ({ setChoosenNfts, choosenNfts, nfts }) => {
    const { project, refresh, expeditionsState } = useContext(MainContext)
    const [expeditions] = expeditionsState
    const { publicKey } = useWallet()
    const [disabledBtn, setDisabledBtn] = useState(false)

    return (
        <div className='fortify__select-nft__container' style={{ background: 'none', border: 'none' }}>
            <div className='fortify__select-nfts-wrapper'>
                {!publicKey ? 'You need to connect your wallet' : !nfts || !expeditions ? <Preloader /> : !nfts.length ? `No NFT to choose` : (
                    <div className='flex flex-row flex-wrap justify-center' style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                        <Grid container sx={{
                            rowGap: '1.2rem',
                            height: 'fit-content'
                        }} columns={{ xs: 9, sm: 16 }}>
                            {nfts.filter(z => !expeditions.find(x => x.rewards.find(y => y.type === 'NFT' && y.data?.nfts?.includes(z.mintAddress)))).map((x, i) => {
                                return (
                                    <Grid item xs={3} sm={4} key={i} style={{ width: '100px', height: '100px', aspectRatio: '1 / 1' }}>
                                        <NFTToSelect i={i} setChoosenNfts={setChoosenNfts} x={x} choosenNfts={choosenNfts} />
                                    </Grid>
                                )
                            })
                            }
                        </Grid>

                    </div>)
                }
            </div>

        </div>
    );
}

const NFTToSelect = ({ x, i, setChoosenNfts, choosenNft, number, choosenNfts }) => {
    return (
        <div className='fortify__select-nfts__nft-container' style={{ backgroundColor: 'transparent', maxWidth: '100%', transform: choosenNfts.includes(x.mintAddress) ? 'scale(0.9)' : "" }}>
            <LazyLoadImage src={x.image} key={i} style={{ height: 'auto', borderColor: 'transparent' }} onClick={() => {
                setChoosenNfts((prev) => {
                    let newPrev = JSON.parse(JSON.stringify(prev || []))
                    if (!newPrev.includes(x.mintAddress)) {
                        newPrev.push(x.mintAddress)
                    } else newPrev = newPrev.filter(z => z !== x.mintAddress)
                    return newPrev
                })
            }} alt={x.name} className='fortify__select-nfts__nft' />
        </div>)

}

