import React, { useContext, useState } from 'react'
import { useCheckMobileScreen } from '../javascript/utility'
import { MainContext } from '../App'
import './../css/prestige.css'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import Preloader from '../components/utility/Preloader'
import { Button, CircularProgress } from '@mui/material'
import { prestige } from '../javascript/prestige'
import { LazyLoadImage } from 'react-lazy-load-image-component'
export default function Prestige() {
    const isMobile = useCheckMobileScreen()
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    const [disableBtn, setDisableBtn] = useState()
    const { project, nfts, refresh, signModalState } = useContext(MainContext)
    const [, setDisplaySignModal] = signModalState
    
    return (
        <div className='home__content-wrapper' style={{ margin: isMobile ? '0.2rem 1rem' : '0.2rem 1rem', backgroundImage: `url(${project.images.prestigeBackground})` }}>
            <div className='home__content' style={{ padding: isMobile ? '1rem 0.5rem' : '1rem 2rem' }}>

                <div className='utility__info-box__yellow utility__info-box__cyan'>
                    <div className='home__anchor-container'>
                        <div>
                            Spend your $FANG to increase the level of your Zalez Beachers to access new Areas! Each area increases your earned rewards to help you obtain the required resources to go on EXPeditions.                        </div>
                    </div>
                </div>

                <div className='prestige__container' style={{ borderRadius: isMobile ? '50px' : '100px' }}>
                    <div className={`flex flex-row ${!isMobile ? 'justify-between' : 'justify-center'} flex-wrap`} style={{ gap: '1rem' }}>
                        <div className='prestige__info'>
                            <div className='title'>
                                Prestige Requirement
                            </div>
                            <div className='description'>
                                {project.prestige.map(x => {
                                    return (
                                        <div>
                                            Level {x.level - 1} {`->`} Level {x.level}: {prestigeTextFormat(x)}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='flex flex-column' style={{ padding: '0px 1rem', gap: '0.5rem', alignItems: 'flex-end' }}>
                            <Button className='utility__material-btn' style={{ color: 'black', '--bg-color': '#DFB728', padding: '0px', width: '130px', maxWidth: '80vw' }} onClick={() => prestige(publicKey, project.projectId, connection, project, signTransaction, refresh, setDisableBtn, setDisplaySignModal)} disabled={disableBtn}>
                                {disableBtn ? <CircularProgress size={'1rem'}/> : ''}Prestige
                            </Button>
                            <div style={{fontSize: '0.8rem'}}>{disableBtn ? 'Don\'t reload the page, This might take some time to prestige...' : ''}</div>
                            <Button className='utility__material-btn' style={{ color: 'black' }}>
                                Select All
                            </Button>
                        </div>
                    </div>

                    {
                        !publicKey ? (<div style={{ margin: '3rem 0px' }}>
                            You need to connect your wallet
                        </div>) : !nfts ? (<div className='flex flex-row justify-center'><Preloader /></div>) : !nfts.find((z) => checkQualifiedNft(z, project)) ? (<div style={{ margin: '3rem 0px' }}>No NFTs to prestige</div>) : (<form id='prestige__select-nft-form' className='prestige__nfts-wrapper'>
                            <div className='prestige__nfts-container'>
                                {nfts.filter(z => checkQualifiedNft(z, project)).map((x, i) => {
                                    return (
                                        <SelectNftToPrestige key={i} x={x} />
                                    )
                                })}
                            </div>
                        </form>)}
                </div>

            </div>
        </div>
    )
}

const SelectNftToPrestige = ({ x }) => {
    const [checked, setChecked] = useState(false)
    return (
        <div className='prestige__nft'>
            <input id={`prestige__nft-select-${x.mintAddress}`} type="radio" checked={checked} onChange={(event) => setChecked(!event.target.checked)} />
            <label htmlFor={`prestige__nft-select-${x.mintAddress}`} className='prestige__nft-img' onClick={() => setChecked(!checked)}>
                <div className='fortify__select-nfts__nft-exp'>
                    <div>
                        EXP: {x.exp || 0}
                    </div>
                    <div>
                        Level: {x.level || 0}
                    </div> </div>
                <LazyLoadImage src={x.image} alt={x.mintAddress}/>
            </label>

        </div>
    )
}
const prestigeTextFormat = (x) => {
    let text = []
    x.price.forEach(y => {
        if (y.type === 'exp') {
            text.push(`${y.data.exp} EXP`)
        }
        if (y.type === 'token') {
            text.push(`${y.data.amount} ${y.data.tokenSymbol}`)
        }

    });
    return text.join(` + `)
}

const checkQualifiedNft = (x, project) => {
    try {
        if (x.level && !project.prestige.find(z => z.level > x.level)) {
            return false
        }
        if (!x.level) x.level = 0
        let level = project.prestige.filter(z => z.level > x.level).sort((a, b) => a.level - b.level)[0]
        if (level) {
            let check = true
            level.price.forEach(y => {
                if (y.type === 'exp') {
                    if (x.exp < y.data.exp) check = false
                }
            });
            return check
        } else return false
    } catch (err) {
        console.log(err)
        return false
    }
}