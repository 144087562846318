import { LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js"
import config from "../config"
import { inWords, sleep } from "./utility"
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress } from "@solana/spl-token"
import { enqueueSnackbar } from "notistack"

export const updatedLevelFormat = (level) => {
    let newLevel = { ...level }
    newLevel.requirements = newLevel.requirements.map(x => {
        if (x.type === 'min_exp') return `Min EXP: ${x.data.minExp}`
        if (x.type === 'expedition-gear') return `Expedetion Gear: ${x.data.amount} ${x.data.name}`
        if (x.type === 'token') return `${x.data.tokenSymbol}: ${x.data.tokenAmount}`
        if (x.type === 'nft') {
            let condition = ''
            if (x.conditions?.length) x.conditions.forEach(y => {
                if (y.type === 'tier') {
                    if (y.data.tier === 'ANY') {
                        condition += `- ${inWords(y.data.numberOfNfts)} of which must be from ${inWords(y.data.numberOfTiers)} of three factions`
                    } else {
                        condition += `- ${inWords(y.data.numberOfNfts)} of which must be a tier ${y.data.tier} from one of three factions`
                    }
                }
                if (y.type === 'faction') {
                    condition += `- ${inWords(y.data.numberOfNfts)} of which must be from the ${y.data.factions.join(' or ')} faction.`
                }
            })
            return `NFT: At least ${x.data.numberOfNfts} ${x.data.collections.map(z => z.name).join('/')} ${condition}`
        }
        return x
    })
    newLevel.rewards = newLevel.rewards.map(x => {
        if (x.type === 'exp') return `EXP: ${x.data.exp}`
        if (x.type === 'expedition-gear') return `Expedition Gear: ${x.data.amount} ${x.data.name}`
        return x
    })
    if (newLevel.bonuses) {
        newLevel.bonuses = newLevel.bonuses.map(x => {
            if (x.text) return x.text
            if (x.type === 'same-faction') return `${x.numberOfNfts} Zalez from the same faction ${x.bonusRewards.map(z => {
                if (z.rewardType === 'cost-reduction') return `- ${z.amount}% cost reduction`
                if (z.rewardType === 'exp-boost') return `- ${z.amount}% EXP boost`
                return z
            })}`
            if (x.type === 'trait-category') return x.text
            return x
        })
    }
    return newLevel
}


export const updateColors = async (publicKey, projectId, refresh, nftColors, setNftColors, disabledBtn, setDisabledBtn, setDisplaySignModal) => {
    try {
        setDisabledBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const req = await fetch(`${config.apiUrl}/update-color`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                nftColors: nftColors,
                nonceId,
                nonceSignature
            })
        })
        const reqJson = await req.json()
        if (reqJson.status) {
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            await refresh()
            setNftColors({})
            setDisabledBtn(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisabledBtn(false)
        }
    } catch (err) {
        setDisabledBtn(false)
        enqueueSnackbar({ message: `Couldn't update colour, please try again later.`, variant: 'error' })
        console.log(err)
    }
}
export const beginJourney = async (publicKey, projectId, nfts, levelId, areaId, setDisableBtn, connection, signTransaction, setChoosenNfts, refresh, setDisplaySignModal) => {
    try {
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        setDisableBtn(true)
        const costReq = await fetch(`${config.apiUrl}/get-costs`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                nfts: nfts,
                levelId: levelId,
                areaId: areaId,
                nonceId, nonceSignature
            })
        })
        const costReqJson = await costReq.json()
        let signature;
        let tx = new Transaction()
        if (costReqJson?.status) {
            for (const x of costReqJson.data) {
                if (x.type === 'token') {
                    const tokenPayment = x.data.tokenAmount
                    const mintToken = new PublicKey(x.data.tokenAddress)
                    const recipientAddress = new PublicKey(x.feesWallet)

                    const associatedTokenFrom = await getAssociatedTokenAddress(
                        mintToken,
                        publicKey
                    );
                    const fromAccount = await getAccount(connection, associatedTokenFrom);
                    const associatedTokenTo = await getAssociatedTokenAddress(
                        mintToken,
                        recipientAddress
                    )

                    if (!(await connection.getAccountInfo(associatedTokenTo))) {
                        const instruction = createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            recipientAddress,
                            mintToken
                        )
                        tx.add(instruction)
                    }
                    const instruction2 = createTransferInstruction(
                        fromAccount.address,
                        associatedTokenTo,
                        publicKey,
                        Math.round(tokenPayment * (x.data.decimals || LAMPORTS_PER_SOL))
                    )
                    tx.add(instruction2)
                }

                if (x.type === 'expedition-gear') {
                    const expGear = x.expGearData
                    const tokenPayment = x.data.amount
                    const mintToken = new PublicKey(expGear.data.tokenAddress)
                    const recipientAddress = new PublicKey(x.feesWallet)

                    const associatedTokenFrom = await getAssociatedTokenAddress(
                        mintToken,
                        publicKey
                    );
                    if (!(await connection.getAccountInfo(associatedTokenFrom))) {
                        enqueueSnackbar({ message: `You dont own any ${x.data.name} to start this mission`, variant: 'error' })
                        setDisableBtn(false)
                        return
                    }
                    const fromAccount = await getAccount(connection, associatedTokenFrom);
                    const associatedTokenTo = await getAssociatedTokenAddress(
                        mintToken,
                        recipientAddress
                    );

                    if (!(await connection.getAccountInfo(associatedTokenTo))) {
                        const instruction = createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            recipientAddress,
                            mintToken
                        )
                        tx.add(instruction)
                    }
                    const instruction2 = createTransferInstruction(
                        fromAccount.address,
                        associatedTokenTo,
                        publicKey,
                        Math.ceil((tokenPayment * (expGear.data.decimals || LAMPORTS_PER_SOL)))
                    )
                    tx.add(instruction2)
                }
            }
            console.log(tx.instructions)
            let blockhash = await connection.getLatestBlockhash()
            tx.feePayer = publicKey
            tx.recentBlockhash = blockhash.blockhash
            const signed = await signTransaction(tx)
            signature = await connection.sendRawTransaction(signed.serialize())
            await connection.confirmTransaction(signature, 'processed')
        } else {
            if (costReqJson.data.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: costReqJson.message, variant: 'error' })
            setDisableBtn(false)
            return
        }
        async function sendReq() {
            const req = await fetch(`${config.apiUrl}/begin-journey`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: projectId,
                    walletAddress: publicKey,
                    nfts: nfts,
                    levelId: levelId,
                    signature: signature,
                    areaId: areaId,
                    nonceId,
                    nonceSignature
                })
            })
            const reqJson = await req.json()
            if (!reqJson.status && reqJson.message === 'Transaction not found') {
                const newReqJson = await sendReq()
                return newReqJson
            }
            return reqJson
        }
        const reqJson = await sendReq()
        if (reqJson?.status) {
            refresh()
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setChoosenNfts([
                undefined, undefined, undefined, undefined
            ])
            setDisableBtn(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        console.log(err)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}

export const claimRewards = async (publicKey, projectId, levelId, areaId, setDisableBtn, setClaimedReward, refresh, connection, signTransaction, setDisplaySignModal) => {
    try {
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        async function sendReq() {
            const req = await fetch(`${config.apiUrl}/claim-reward`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: projectId,
                    walletAddress: publicKey,
                    levelId: levelId,
                    areaId: areaId,
                    nonceId,
                    nonceSignature
                })
            })
            const reqJson = await req.json()
            if (reqJson.message === 'Token Account not found') {
                const mintToken = new PublicKey(reqJson.data.tokenAddress)
                const associatedTokenTo = await getAssociatedTokenAddress(
                    mintToken,
                    publicKey
                );
                if (!(await connection.getAccountInfo(associatedTokenTo))) {
                    const tx = new Transaction()
                    tx.add(
                        createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            publicKey,
                            mintToken
                        )
                    )
                    const blockHash = await connection.getLatestBlockhash();
                    tx.feePayer = publicKey;
                    tx.recentBlockhash = blockHash.blockhash;

                    const signed = await signTransaction(tx);
                    const sign = await connection.sendRawTransaction(signed.serialize());

                    await connection.confirmTransaction(sign, 'processed');
                }
                await sleep(5000)
                const newReqJson = await sendReq()
                return newReqJson
            }
            return reqJson
        }
        const reqJson = await sendReq()
        if (reqJson?.status) {
            setClaimedReward(reqJson.data)
            refresh()
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setDisableBtn(false)
        } else {
            if(reqJson.data.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        console.log(err)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}



export const cancelMissions = async (publicKey, projectId, levelId, areaId, setDisableBtn, connection, signTransaction, refresh, project, setOpenCancelModal, setDisplaySignModal) => {
    try {
        setDisableBtn(true)
        const costReqJson = project.cancelMissionCost
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        let signature;
        let tx = new Transaction()
        for (const x of costReqJson) {
            if (x.type === 'token') {
                const tokenPayment = x.data.tokenAmount
                const mintToken = new PublicKey(x.data.tokenAddress)
                const recipientAddress = new PublicKey(project.feesWallet)

                const associatedTokenFrom = await getAssociatedTokenAddress(
                    mintToken,
                    publicKey
                );
                const fromAccount = await getAccount(connection, associatedTokenFrom);
                const associatedTokenTo = await getAssociatedTokenAddress(
                    mintToken,
                    recipientAddress
                )

                if (!(await connection.getAccountInfo(associatedTokenTo))) {
                    const instruction = createAssociatedTokenAccountInstruction(
                        publicKey,
                        associatedTokenTo,
                        recipientAddress,
                        mintToken
                    )
                    tx.add(instruction)
                }
                const instruction2 = createTransferInstruction(
                    fromAccount.address,
                    associatedTokenTo,
                    publicKey,
                    Math.round(tokenPayment * (x.data.decimals || LAMPORTS_PER_SOL))
                )
                tx.add(instruction2)
            }
        }
        let blockhash = await connection.getLatestBlockhash()
        tx.feePayer = publicKey
        tx.recentBlockhash = blockhash.blockhash
        const signed = await signTransaction(tx)
        signature = await connection.sendRawTransaction(signed.serialize())
        await connection.confirmTransaction(signature, 'processed')
        async function sendReq() {
            const req = await fetch(`${config.apiUrl}/cancel-missions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: projectId,
                    walletAddress: publicKey,
                    levelId: levelId,
                    signature: signature,
                    areaId: areaId,
                    nonceId,
                    nonceSignature
                })
            })
            const reqJson = await req.json()
            if (!reqJson.status && reqJson.message === 'Transaction not found') {
                const newReqJson = await sendReq()
                return newReqJson
            }
            return reqJson
        }
        const reqJson = await sendReq()
        if (reqJson?.status) {
            refresh()
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setDisableBtn(false)
            setOpenCancelModal(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        console.log('ERROR', err)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}

