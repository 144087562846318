import { useContext, useState } from 'react'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Button, CircularProgress, FormControlLabel, Switch } from '@mui/material'
import { MainContext } from '../../App'
import { verifyWallet } from '../../javascript/utility'
export const SignModal = () => {
    const { signMessage, publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()
    const { project, signModalState } = useContext(MainContext)
    const [ledger, setLedger] = useState(false)
    const [disableSignBtn, setDisableSignBtn] = useState(false)
    const [displayModal, setDisplayModal] = signModalState
    if (displayModal) {
        return (
            <>
                <div className='modal-wrapper'>
                    <div className='modal-bg'></div>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div style={{ justifyContent: 'center', textAlign: 'center', color: 'black', fontSize: '1.5rem', fontWeight: '600', padding: '2rem', border: '2px solid black' }} className='font-marker'>Sign Message
                                <div style={{ fontSize: '1rem', marginTop: '1rem', fontFamily: 'DM Sans' }}>You need to sign message to verify ownership of wallet</div>
                                <div style={{ margin: '0.8rem' }}>
                                    <FormControlLabel style={{
                                        padding: '0.1rem 0.8rem 0.1rem 0.8rem',
                                        background: '#39394a52',
                                        margin: '0px'
                                    }} sx={{
                                        '& .MuiTypography-root': {
                                            fontWeight: '600',
                                            textTransform: 'uppercase',
                                            fontSize: '0.9rem'
                                        }
                                    }} control={<Switch checked={ledger} onChange={() => setLedger(!ledger)} />} labelPlacement="start" label="Using Ledger?: " />
                                </div>
                                <Button className='font-montserrat utility-btns__purple-btn' sx={{
                                    color: 'black',
                                    border: '2px solid #DBEB56',
                                    textTransform: 'uppercase',
                                    background: '#53C799 !important',
                                    fontWeight: '700',
                                }} onClick={() => verifyWallet(signMessage, setDisplayModal, publicKey, project.projectId, ledger, sendTransaction, connection, setDisableSignBtn)} disabled={disableSignBtn}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {disableSignBtn && (<CircularProgress size={'1rem'} />)}
                                        <div>Sign Message</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}