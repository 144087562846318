import React, { createContext, useEffect, useState } from 'react'
import { updateAreas, updateLevels, updateMissions, updateNfts, updateProject, updateWalletAccount } from './javascript/utility'
import { Route, Routes } from 'react-router-dom'
import MainPage from './pages/MainPage'
import './css/utility.css'
import MainRoutes from './components/utility/MainRouter.js'
import { useWallet } from '@solana/wallet-adapter-react'
import { SnackbarProvider } from 'notistack'
import { SignModal } from './components/utility/SignModal'

export default function App() {
    const params = { id: 'zalez' }
    const [project, setProject] = useState()
    const [nfts, setNfts] = useState()
    const [expeditions, setExpeditions] = useState()
    const [areas, setAreas] = useState()
    const [missions, setMissions] = useState()
    const [walletAccount, setWalletAccount] = useState()
    const [levels, setLevels] = useState()
    const [expGears, setExpGears] = useState()
    const [navbarTitle, setNavbarTitle] = useState()
    const [usedNfts, setUsedNfts] = useState([])
    const { publicKey } = useWallet()
    const [displaySignModal, setDisplaySignModal] = useState(false)

    const refresh = async (check, project, areas, levels, nfts, missions, walletAccount) => {
        if (!check || (check && project)) await updateProject(setProject, params.id)
        if (!check || (check && areas)) await updateAreas(setAreas, params.id)
        if (!check || (check && levels)) await updateLevels(setLevels, params.id)

        if (!check || (check && nfts)) await updateNfts(setNfts, publicKey, params.id)
        if (!check || (check && missions)) await updateMissions(setMissions, publicKey, params.id)
        if (!check || (check && walletAccount)) await updateWalletAccount(setWalletAccount, publicKey, params.id)
    }
    useEffect(() => {
        updateProject(setProject, params.id)
        updateAreas(setAreas, params.id)
        updateLevels(setLevels, params.id)
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateNfts(setNfts, publicKey, params.id)
        updateMissions(setMissions, publicKey, params.id)
        updateWalletAccount(setWalletAccount, publicKey, params.id)
    }, [publicKey, params.id])
    return (
        <MainContext.Provider value={{
            project: project,
            navbarTitle: [navbarTitle, setNavbarTitle],
            nfts: nfts,
            areas: areas,
            missions: missions,
            levels: levels,
            walletAccount: walletAccount,
            refresh: refresh,
            expeditionsState: [expeditions, setExpeditions],
            usedNftsState: [usedNfts, setUsedNfts],
            expGearsState: [expGears, setExpGears],
            signModalState: [displaySignModal, setDisplaySignModal]
        }}>
            <SignModal displayModal={displaySignModal} setDisplayModal={setDisplaySignModal} />
            <SnackbarProvider
                autoHideDuration={5000}
                maxSnack={5}
                // action={key => <Button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', padding: '0.1rem', minWidth: 'fit-content'}} onClick={() => closeSnackbar(key)}><CloseButton id={key} /></Button>}
                className={'utility__snack-bar'}
            />

            <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path='/*' element={<MainRoutes />} />
            </Routes>
        </MainContext.Provider>
    )
}

export const MainContext = createContext({
    project: undefined,
    navbarTitle: undefined,
    areas: undefined,
    nfts: undefined,
    levels: undefined,
    missions: undefined,
    refresh: undefined,
    walletAccount: undefined,
    usedNftsState: undefined,
    expeditionsState: undefined,
    expGearsState: undefined,
    signModalState: undefined
})
