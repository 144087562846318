import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Area from '../components/fortify/Area'
import AreasList from '../components/fortify/AreasList'

export default function Fortify() {
  return (
    <Routes>
      <Route path='/' element={<AreasList />} />
      <Route path='/:areaId' element={<Area />} />
    </Routes>
  )
}