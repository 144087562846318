import { useEffect, useMemo, useState } from "react";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const Timer = ({ deadline = new Date().toString() }) => {
    const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
    const [time, setTime] = useState(parsedDeadline - Date.now());
    useEffect(() => {
        const interval = setInterval(
            () => setTime(parsedDeadline - Date.now()),
            1000,
        );

        return () => clearInterval(interval);
    }, []);
    if (time > 0) {
        return (
            <div className="utility__timer">
                {Object.entries({
                    Days: time / DAY,
                    Hours: (time / HOUR) % 24,
                    Minutes: (time / MINUTE) % 60,
                    Seconds: (time / SECOND) % 60,
                }).map(([label, value]) => {
                    return (<div key={label}>
                        <div>
                            {`${Math.floor(value)}`.padStart(2, "0")}{label[0]}
                        </div>
                    </div>)
                })}
            </div>
        );
    } else {
        return (
            <div className="utility__timer">
                Ended
            </div>
        )
    }
}
