import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../../App'
import { CircularProgress, IconButton } from '@mui/material'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { buyExpGear } from '../../javascript/expeditions'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
export default function TopUp() {
    const { project, expGearsState } = useContext(MainContext)
    const [expGears, setExpGears] = expGearsState
    useEffect(() => {
        if (!expGears) setExpGears(project.expeditionGears)
    }, [expGears, project.expeditionGears, setExpGears])
    return (
        <div className='utility__info-box__yellow utility__info-box__yellow-no-hover exp__yellow-container'>
            <div className='flex flex-column'>
                <div className='font-marker' style={{ textAlign: 'center', fontSize: '1.3rem', width: '100%', borderBottom: '2px solid black', padding: '1rem 0px 0px 0px' }}>
                    TOP UP YOUR RESOURCES
                </div>

                <div className={`flex flex-row`} style={{ padding: '0px 0.5rem', overflowX: 'scroll' }}>
                    <div className='flex flex-column' style={{ gap: '1rem', minWidth: '120px', textAlign: 'left', fontSize: '0.9rem', borderRight: '2px solid black', padding: '0.5rem 1rem 1rem 0.2rem' }}>
                        <div style={{ fontWeight: '700' }}>
                            Your Balances
                        </div>
                        {expGears && expGears.map((x, i) => {
                            return (
                                <div key={i} className='flex flex-row align-center'>
                                    {x.name}: {x.balance !== undefined ? x.balance : <CircularProgress style={{ marginLeft: '0.5rem' }} size={'1rem'} color="inherit" />}
                                </div>
                            )
                        })}
                    </div>
                    <div className='flex flex-column flex-wrap' style={{ padding: '0.5rem 1.4rem', gap: '0.4rem' }}>
                        {expGears && expGears.filter(x => x.data.prices).map((x, i) => {
                            return (<TopUpItem key={i} x={x} />)
                        })}
                    </div>
                </div>
            </div>
        </div>

    )
}


const TopUpItem = ({ x }) => {
    const [quantity, setQuantity] = useState(0)
    const { project, expGearsState, signModalState} = useContext(MainContext)
    const [, setDisplaySignModal] = signModalState
    const [expGears] = expGearsState
    const { publicKey, signTransaction } = useWallet()
    const [disableBtn, setDisableBtn] = useState(false)
    const { connection } = useConnection()
    return (
        <div className='flex flex-row' style={{ gap: '1rem' }}>
            <div className='flex flex-column' style={{ fontSize: '0.9rem', textAlign: 'left', width: '100px' }}>
                <div>{x.name}</div>
                <img src={x.data.image} alt={x.data.symbol} className='exp__top-up__price__image' />
            </div>
            <div className='flex flex-column justify-around'>
                <IconButton className='exp__top-up__price__icon-btn' onClick={() => setQuantity(quantity + 1)}>
                    <FaPlus />
                </IconButton>
                <div>
                    {quantity}
                </div>
                <IconButton className='exp__top-up__price__icon-btn' onClick={() => {
                    if (quantity - 1) {
                        setQuantity(quantity - 1)
                    }
                }}>
                    <FaMinus />
                </IconButton>
            </div>
            <div className='flex flex-column justify-around'>
                {x.data.prices.map((y, i) => {
                    return (<button disabled={disableBtn} onClick={() => buyExpGear(publicKey, project, quantity, y, x, setDisableBtn, connection, signTransaction, setDisplaySignModal)} key={i} className='exp__cyan-btn font-marker'>{y.type === 'SOL' ? 'BUY' : expGears.find(z => z.data.tokenAddress === y.data.tokenAddress) ? 'SWAP' : 'CONSUME'}</button>)
                })}
            </div>

            <div className='flex flex-column justify-around exp__top-up__price__info-container' style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
                {x.data.prices.map((x, i) => {
                    return (<div key={i} className='flex flex-row' style={{ gap: '1rem' }}>
                        <div>{x.price}</div>
                        <div> {x.type === 'SOL' ? 'Buy with Sol' : expGears.find(z => z.data.tokenAddress === x.data.tokenAddress) ? `Exchange ${x.data.symbol}` : `Use ${x.data.symbol}`}</div>
                    </div>)
                })}
            </div>
        </div>
    )
}