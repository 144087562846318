import React, { useContext, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Fortify from '../../pages/Fortify'
import Navbar from './Navbar'
import { MainContext } from '../../App'
import SideBar from './SideBar'
import { BrowserView } from './DeviceDetect'
import HamburgerIcon from './../../assets/img/utility/icons/hamburger.svg'
import Preloader from './Preloader'
import Prestige from '../../pages/Prestige'
import Expedition from '../../pages/Expedition'
import EXPLeaderboard from '../../pages/EXPLeaderboard'
import Dashboard from '../../pages/Dashboard'
import CreateProject from '../../pages/CreateProject'
export default function MainRoutes() {
    const { project, areas, levels, missions } = useContext(MainContext)
    const [openedSidebar, setOpenedSidebar] = useState(false)
    if (project && areas && levels) {
        return (
            <>
                <div className='home__body'>
                    <Navbar openedSidebarState={[openedSidebar, setOpenedSidebar]} />
                    <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
                        <SideBar openedState={[openedSidebar, setOpenedSidebar]} />
                        <BrowserView>
                            <div style={{ width: '100px', maxWidth: '30vw' }}>
                                <div className='utility__navbar-logo utility__sidebar-btn utility__sidebar-btn-yellow' style={{ maxWidth: '30vw', margin: 'auto' }} onClick={() => setOpenedSidebar(!openedSidebar)}>
                                    <img src={HamburgerIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                                </div>
                            </div>
                        </BrowserView>
                        <div style={{ width: '-webkit-fill-available' }}>
                            <Routes>
                                <Route path='/fortify/*' element={<Fortify />} />
                                <Route path='/prestige/*' element={<Prestige />} />
                                <Route path='/dashboard/*' element={<Dashboard />} />
                                {/* <Route path='/create-project/*' element={<CreateProject />} /> */}
                                <Route path='/expeditions/*' element={<Expedition />} />
                                <Route path='/leaderboard/*' element={<EXPLeaderboard />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div className='utility__loader-container'>
                <Preloader />
            </div>
        )
    }
}
