import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../App'
import { getTokenInfo, sleep, useCheckMobileScreen } from '../javascript/utility'
import { Box, Button, Grid, IconButton, Input, TextField, Tooltip, styled, tooltipClasses } from '@mui/material'
import { FaInfoCircle } from 'react-icons/fa'
import { RxCross1 } from 'react-icons/rx'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { enqueueSnackbar } from 'notistack'
import { launchExpedition, launchExpeditionGetUsedNfts } from '../javascript/dashboard'
import { Metaplex } from '@metaplex-foundation/js'
import { Connection, PublicKey } from '@solana/web3.js'
import Preloader from '../components/utility/Preloader'
import { NFTSelect } from '../components/dashboard/SelectNFT'
import { updatExpeditions } from '../javascript/expeditions'

export default function Dashboard() {
  const { project, signModalState, expeditionsState, usedNftsState } = useContext(MainContext)
  const { publicKey } = useWallet()
  const isMobile = useCheckMobileScreen()
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [minLevel, setMinLevel] = useState(0)
  const [tokenAmount, setTokenAmount] = useState(0)
  const [tokenAddress, setTokenAddress] = useState('')

  const [expTime, setExpTime] = useState('')
  const [expFees, setExpFees] = useState('')
  const [expKits, setExpKits] = useState('')
  const [spotsAvailable, setSpotsAvailable] = useState('')
  const [reoccuring, setReoccuring] = useState(false)
  const [occurrences, setOccurences] = useState('')
  const [maxExpPerWallet, setMaxExpPerWallet] = useState('')
  const [rewards, setRewards] = useState([])
  const [disableBtn, setDisableBtn] = useState(false)
  const [showAddReward, setShowAddReward] = useState(false)
  const [, setDisplaySignModal] = signModalState
  const [nftsOwnedByDaoWallet, setNftsOwnedByDaoWallet] = useState()
  const [expeditions, setExpeditions] = expeditionsState
  const [usedNfts, setUsedNfts] = usedNftsState

  useEffect(() => {
    console.log(tokenAddress)
  }, [tokenAddress])
  const requirementOptions = [
    {
      title: 'Minimum Level',
      info: 'Please enter the minimum level requirement to go on the EXPedition. If left blank, the minimum level will be set to 0',
      type: 'number',
      value: minLevel,
      setValue: setMinLevel
    },
    {
      title: 'Expedition Time (H)',
      info: `Please enter the time in which the EXPedition will last. The countdown will begin immediately once you have selected "Launch EXPedition" or for when you've scheduled your EXPedtion to begin`,
      type: 'number',
      value: expTime,
      setValue: setExpTime
    },
    {
      title: 'Expedition Fees',
      info: `Set the SOL fee charged to all users for each NFT they send on the EXPedition`,
      type: 'number',
      value: expFees,
      setValue: setExpFees
    },
    {
      title: 'EXPedition Kits Required',
      info: 'Set the required number of EXPedition kits required to go on the mission. If none entered, then the amount will be automatically set to 1',
      type: 'number',
      value: expKits,
      setValue: setExpKits
    },
    {
      title: 'Token Address',
      info: `Set the Token fee charged to all users for each NFT they send on the EXPedition`,
      type: 'text',
      value: tokenAddress,
      setValue: setTokenAddress
    },
    {
      title: 'Token Amount',
      info: `Amount of tokens required to pay to send NFT on expedition`,
      type: 'number',
      value: tokenAmount,
      setValue: setTokenAmount
    },

    {
      title: 'Total Available Spots',
      info: 'Set the total available spots for the EXPedition. This will determine how many uses and thus how many prizes will be distributed in total',
      type: 'number',
      value: spotsAvailable,
      setValue: setSpotsAvailable
    },
    {
      title: 'Max EXPeditions/Wallet',
      info: 'Specify how many NFTs can go on the EXPedition from the connected wallet.',
      type: 'number',
      value: maxExpPerWallet,
      setValue: setMaxExpPerWallet
    }
  ]

  useEffect(() => {
    if (!expeditions) {
      updatExpeditions(setExpeditions, publicKey, project.projectId)
    }
    if (!usedNfts?.length) {
      launchExpeditionGetUsedNfts(project.projectId, publicKey, setUsedNfts)
    }
  }, [project, publicKey])

  const ScheduleLaunchExpeditionBtn = ({ startingTime }) => {
    return (<Button disabled={disableBtn} sx={{
      color: 'black',
      border: '2px solid #DBEB56',
      textTransform: 'none',
      fontSize: '0.8rem',
      background: '#53C799'
    }} onClick={() => launchExpedition(name, description, minLevel, expTime, expFees, expKits, spotsAvailable, tokenAddress, tokenAmount, maxExpPerWallet, reoccuring, occurrences, rewards, setDisableBtn, project.projectId, publicKey, setDisplaySignModal, startingTime)}>Launch EXPedition:</Button>)
  }

  return (
    <div className='home__content-wrapper' style={{ margin: isMobile ? '0.2rem 1rem' : '0.2rem 1rem', backgroundImage: `url(${project.images.expeditionBackground})` }}>
      <div className='home__content' style={{ padding: isMobile ? '0px 0.5rem' : '0px 2rem' }}>
        <div className='flex flex-column' style={{ gap: '1.5rem' }}>

          <div className='utility__info-box__yellow exp-dashboard__form' style={{ borderRadius: '5px', maxWidth: '80vw', margin: '2rem auto', width: '450px', background: '#f2c522d9' }}>
            <div className='font-marker' style={{ fontSize: '2rem' }}>Expedition Creation</div>
            <div className='flex flex-row justify-between flex-wrap' style={{ gap: '0.5rem', fontSize: '1.3rem', }}>
              <div className='font-marker'>Expedition Name: </div>
              <input type='text' value={name} onChange={(event) => setName(event.target.value)} />
            </div>

            <div className='full-width flex flex-column' style={{ alignItems: 'baseline' }}>
              <div className='font-marker' style={{ fontSize: '1.3rem' }}>EXPEdition Description:</div>
              <input type='text' className='full-width' value={description} onChange={(event) => setDescription(event.target.value)} style={{ minHeight: '40px' }} />
            </div>

            <div className='full-width flex flex-column' style={{ alignItems: 'baseline' }}>
              <div className='font-marker' style={{ fontSize: '1.3rem' }}>Specify Requirements:</div>
              <div style={{ fontSize: '0.8rem', textAlign: 'left' }}>
                Specify the details required to be eligible to complete the EXPedition as well as the required time and costs to launch
              </div>
              <div className='flex flex-row flex-wrap full-width justify-between'>
                {requirementOptions.map((x, i) => {
                  return <RequirementInput x={x} key={i} />
                })}
                <ReOccuringForm reoccuring={reoccuring} setReoccuring={setReoccuring} occurrences={occurrences} setOccurences={setOccurences} />
              </div>
            </div>

            <div className='full-width flex flex-column' style={{ alignItems: 'baseline' }}>
              <div className='font-marker' style={{ fontSize: '1.3rem' }}>Rewards and Probabilities:</div>
              <div style={{ fontSize: '0.8rem', textAlign: 'left' }}>
                Specify all the rewards and their probabilities for the EXPedition
              </div>
              <Button sx={{
                padding: '0.2rem 0.5rem',
                fontFamily: 'DM Sans',
                color: 'black',
                background: '#88752d !important',
                marginTop: '0.2rem'
              }} onClick={() => setShowAddReward(true)}>Add Reward</Button>
              <AddRewardEmbed rewards={rewards} setRewards={setRewards} setShowAddReward={setShowAddReward} showAddReward={showAddReward} nftsOwnedByDaoWallet={nftsOwnedByDaoWallet} setNftsOwnedByDaoWallet={setNftsOwnedByDaoWallet} />

              {rewards.length ? (<div className='flex flex-row' style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <Grid container spacing={{ xs: 0 }} columns={{ xs: 9 }}>
                    <Grid item xs={3} >

                    </Grid>
                    <Grid item xs={3} className='font-marker'>
                      Quantity
                    </Grid>
                    <Grid item xs={3} className='font-marker'>
                      Probability
                    </Grid>
                  </Grid>
                  {rewards.map((x, i) => {
                    return (
                      <Grid container key={i} spacing={{ xs: 0 }} columns={{ xs: 9 }} style={{ width: '100%', fontSize: '0.9rem' }}>
                        <Grid item xs={1} >
                          <IconButton onClick={() => setRewards(rewards.filter((y, i2) => i2 !== i))} sx={{ padding: '0px' }}><RxCross1 style={{ fontSize: '0.8rem', strokeWidth: '2', color: '#c60707' }} /></IconButton>
                        </Grid>
                        <Grid item xs={2} style={{ fontWeight: '600', textDecoration: 'underline', textAlign: 'left' }}>
                          {x.data.tokenSymbol || x.data.name || x.type}
                        </Grid>
                        <Grid item xs={3} >
                          <input type='number' value={x.amount} style={{ width: '90%' }} onChange={(event) => {
                            setRewards(rewards.map((x, i2) => {
                              if (i === i2) x.amount = event.target.value
                              return x
                            }))
                          }} />
                        </Grid>
                        <Grid item xs={3}>
                          <input type='number' value={x.probability} style={{ width: '90%' }} onChange={(event) => {
                            setRewards(rewards.map((x, i2) => {
                              if (i === i2) x.probability = event.target.value
                              return x
                            }))
                          }} />
                        </Grid>
                      </Grid>
                    )
                  })}
                </div>
              </div>) : (<></>)}

              <div className='flex flex-row' style={{ gap: '1rem', margin: '1rem 0px' }}>
                <div style={{ fontWeight: '700' }}>Probability Check:</div>
                <input style={{ width: '40px' }} value={rewards.length ? rewards.reduce((a, b) => a + Number(b.probability), 0) : 0} />
              </div>

              <Box style={{ fontSize: '0.9rem' }} sx={{
                '& .MuiButtonBase-root': {
                  color: 'black',
                  border: '2px solid #DBEB56',
                  textTransform: 'none',
                  fontSize: '0.8rem',
                  background: '#53C799'
                }
              }}>
                <div className='flex flex-row align-center' style={{ gap: '0.5rem', margin: '0.2rem 0px' }}>
                  <Button disabled={disableBtn} onClick={() => launchExpedition(name, description, minLevel, expTime, expFees, expKits, spotsAvailable, tokenAddress, tokenAmount, maxExpPerWallet, reoccuring, occurrences, rewards, setDisableBtn, project.projectId, publicKey, setDisplaySignModal)}>Launch EXPedition:</Button>
                  <div>Starts the EXPedition Immediately</div>
                </div>
                <div className='flex flex-row align-center' style={{ gap: '0.5rem', margin: '0.2rem 0px' }}>
                  <ScheduleExpeditionBtn ScheduleExpdition={ScheduleLaunchExpeditionBtn} />
                  <div>Starts the EXPedition at a specific time</div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const ReOccuringForm = ({ reoccuring, setReoccuring, occurrences, setOccurences }) => {
  const [open, setOpen] = useState(false)
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '80vw',
      width: '400px',
      whiteSpace: 'pre-line',
      padding: '0.5rem 1rem'
    },
  });

  return (
    <div className='flex flex-column requirements-req' style={{ fontSize: '0.8rem' }}>
      <div className='flex flex-row' style={{ gap: '0.3rem' }}>
        <CustomWidthTooltip title={<div onClick={() => setOpen(!open)}>
          If you choose to select "Make recurring", please specify how many occurrences you would like. If unlimited, leave the # of Occurrences blank
        </div>} placement="top" open={open} onClose={() => setOpen(false)} className='' arrow>
          <IconButton onClick={() => setOpen(!open)} sx={{ padding: '0px' }}><FaInfoCircle style={{ fontSize: '0.8rem' }} /></IconButton>
        </CustomWidthTooltip>
        <div>Make Reoccuring</div>
        <input type='checkbox' value={reoccuring} onChange={(event) => setReoccuring(event.target.value)} />
      </div>
      <div className='flex flex-row' style={{ gap: '0.3rem' }}>
        <div># of Occurrences</div>
        <input type='number' value={occurrences} onChange={(event) => setOccurences(event.target.value)} />
      </div>
    </div>
  )
}

const AddRewardEmbed = ({ setRewards, setShowAddReward, showAddReward, rewards, nftsOwnedByDaoWallet, setNftsOwnedByDaoWallet }) => {
  const [typeOfReward, setTypeOfReward] = useState('NFT')
  const [quantity, setQuantity] = useState(1)
  const { connection } = useConnection()
  const { project, usedNftsState, expeditionsState } = useContext(MainContext)
  const [expeditions] = expeditionsState
  const [usedNfts] = usedNftsState
  const [disableBtn, setDisableBtn] = useState(false)
  const [probability, setProbability] = useState(1)
  const { publicKey } = useWallet()
  const [nameNftReward, setNameNftReward] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [choosenNfts, setChoosenNfts] = useState([])
  const typeOfRewardOptions = ['NFT', 'SPL-TOKEN', 'EXP', 'SOL']

  useEffect(() => {
    if (project) {
      async function updateRewards() {
        if (typeOfReward === 'NFT' && !nftsOwnedByDaoWallet) {
          const metaplex = new Metaplex(connection)
          let nfts2 = await metaplex.nfts().findAllByOwner({ owner: new PublicKey(project.daoWallet.walletAddress) })
          for (let i = 0; i <= nfts2.length; i += 10) {
            let nfts = nfts2.slice(i, i + 10)
            await Promise.all(nfts.map(async nft => {
              if (nft?.uri) {
                async function loadNft(nft, corsproxy) {
                  let nftMetadata;
                  if (corsproxy) {
                    const nftMetadata2 = await fetch(nft.uri,
                      {
                        headers: {
                          'Content-Type': 'application/json',
                        }
                      })
                    const nftMetadataJson = await nftMetadata2.json()
                    nftMetadata = { ...nft, json: nftMetadataJson }
                  } else {
                    try {
                      const nftMetadata2 = await fetch(`https://cors.solanacryptic.in/${nft.uri.replace('https://', '')}`,
                        {
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest'
                          }
                        })
                      const nftMetadataJson = await nftMetadata2.json()
                      nftMetadata = { ...nft, json: nftMetadataJson }
                    } catch {
                      nftMetadata = await metaplex.nfts().load({ metadata: nft })
                    }
                  }

                  if (nftMetadata.json) {
                    await setNftsOwnedByDaoWallet((prev) => {
                      const newPrev = JSON.parse(JSON.stringify(prev || []))

                      nftMetadata.json.mintAddress = nftMetadata.mint?.address?.toString() || nftMetadata?.mintAddress?.toString()
                      if (!newPrev.find(z => nftMetadata.mint?.address?.toString() === z.mintAddress)) {
                        newPrev.push(nftMetadata.json)
                      }
                      return newPrev
                    })
                  } else {
                    return await loadNft(nft, true)
                  }
                }
                try {
                  console.log(nft)
                  await loadNft(nft)
                } catch (error) {
                  console.log(nft.mintAddress.toString())
                  console.log(error)
                  // await loadNft(nft, true)
                }
              } else {
                console.log('No URI', nft)
              }
            }))
          }
        }
      }
      updateRewards()
    }
  }, [typeOfReward, project])
  const handleAddReward = async () => {
    try {
      setDisableBtn(true)
      if (!typeOfReward || !quantity || !probability) {
        setDisableBtn(false)
        return enqueueSnackbar({ variant: 'error', message: `You need to fill each required field.` })
      }
      let newReward = { type: typeOfReward, amount: quantity, probability: probability, data: {} }
      if (newReward.type === 'SPL-TOKEN') {
        if (!tokenAddress) {
          setDisableBtn(false)
          return enqueueSnackbar({ variant: 'error', message: `You need to fill each required field.` })
        }
        const token = await getTokenInfo(tokenAddress, connection)
        if (!token.status) {
          setDisableBtn(false)
          return enqueueSnackbar({ message: token.message, variant: 'error' })
        } else {
          newReward.data.tokenAddress = tokenAddress
          newReward.data.tokenSymbol = token.data.tokenSymbol
        }
      } else if (newReward.type === 'NFT') {
        if (!choosenNfts || !nameNftReward) {
          setDisableBtn(false)
          return enqueueSnackbar({ variant: 'error', message: `You need to fill each required field.` })
        }
        newReward.data.name = nameNftReward
        newReward.data.nfts = choosenNfts
      }
      setShowAddReward(false)
      setDisableBtn(false)
      setRewards([...rewards, newReward]);
      setChoosenNfts([])
    } catch (err) {
      console.log(err)
      setDisableBtn(false)
      enqueueSnackbar({ variant: 'error', message: `An unknown error occured: ${err.message || ''}` })
    }
  };


  if (showAddReward) {
    return (
      <div className='modal-wrapper'>
        <div className='modal-bg' onClick={() => setShowAddReward(false)}></div>
        <div className='modal-container'>
          <Box sx={{
            '& .MuiOutlinedInput-root': {
              padding: '0.2rem 0.2rem 0.2rem 0.2rem',
              background: '#735f0f9e',
            },
            '& .MuiTextField-root': {
              width: '100%'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'black'
            },
            '& .Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'black'
              }
            }
          }}>
            <div className='exp-dashboard__modal exp-dashboard__modal-form'>
              <form className='flex flex-column' style={{ gap: '0.8rem' }}>
                <div className='flex flex-row flex-wrap' style={{ gap: '1rem' }}>
                  <div className='flex flex-column align-center justify-center'>
                    <div className='requirements-req__title'>
                      Type:
                    </div>
                    <select value={typeOfReward} onChange={(event) => setTypeOfReward(event.target.value)}>
                      {typeOfRewardOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='flex flex-column align-center'>
                    <div className='requirements-req__title'>
                      Quantity:
                    </div>
                    <input type='number' value={quantity} onChange={(event) => setQuantity(event.target.value)} />
                  </div>


                  <div className='flex flex-column align-center'>
                    <div className='requirements-req__title'>
                      Probability:
                    </div>
                    <input type='number' value={probability} onChange={(event) => setProbability(event.target.value)} />
                  </div>
                </div>

                {typeOfReward === 'NFT' ? (
                  <div>
                    <div className='flex flex-column align-center'>
                      <div className='requirements-req__title'>
                        Name of NFT Reward:
                      </div>
                      <TextField
                        multiline
                        rows={nameNftReward.split('\n').length > 2 ? 2 : nameNftReward.split('\n').length}
                        value={nameNftReward}
                        onChange={(event) => setNameNftReward(event.target.value)}
                        variant="outlined"
                      />
                    </div>
                    <div className='flex flex-column align-center'>
                      <div className='requirements-req__title'>
                        NFTs:
                      </div>
                      {!nftsOwnedByDaoWallet ? (<Preloader />) : <NFTSelect nfts={nftsOwnedByDaoWallet.filter(z => !usedNfts?.includes(z.mintAddress) && !expeditions.find(sk => sk.rewards.find(y => y.type === 'NFT' && y?.data?.nfts?.includes(z.mintAddress))) && !rewards.find(s => s.type === 'NFT' && s?.data?.nfts?.includes(z.mintAddress)))} choosenNfts={choosenNfts} setChoosenNfts={setChoosenNfts} />}
                    </div>
                  </div>
                ) : typeOfReward === 'SPL-TOKEN' ? (
                  <div>
                    <div className='flex flex-column align-center'>
                      <div className='requirements-req__title'>
                        Token Address:
                      </div>
                      <input
                        style={{ width: '100%' }}
                        value={tokenAddress}
                        onChange={(event) => setTokenAddress(event.target.value)}
                      />
                    </div>
                  </div>
                ) : typeOfReward === 'EXP' ? (<></>) : (<></>)}
                <Button
                  sx={{
                    padding: '0.2rem 0.5rem',
                    fontFamily: 'DM Sans',
                    color: 'black',
                    background: '#88752d !important',
                    marginTop: '0.2rem'
                  }} onClick={() => handleAddReward()}>Add Reward</Button>
              </form>
            </div>
          </Box>
        </div>
      </div>
    )
  }
}
const RequirementInput = ({ x }) => {
  const [open, setOpen] = useState(false)
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '80vw',
      width: '400px',
      whiteSpace: 'pre-line',
      padding: '0.5rem 1rem'
    },
  });
  return <div className='requirements-req'>
    <div className='requirements-req__title'>
      {x.title}:
    </div>
    <div className='flex flex-row' style={{ gap: '0.3rem' }}>
      <input required type={x.type} className='full-width' value={x.value} onChange={(event) => x.setValue(event.target.value)} />
      <CustomWidthTooltip title={<div onClick={() => setOpen(!open)}>
        {x.info}
      </div>} placement="top" open={open} onClose={() => setOpen(false)} className='' arrow>
        <IconButton onClick={() => setOpen(!open)} sx={{ padding: '0px' }}><FaInfoCircle style={{ fontSize: '0.8rem' }} /></IconButton>
      </CustomWidthTooltip>
    </div>
  </div>
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip disableFocusListener={false} {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '80vw',
    backgroundColor: '#F9CB26',
    border: '2px solid black',
    borderRadius: '0px',
    color: 'black',
    padding: '0.5rem 1rem',
    fontSize: '1rem'
  },
});

const ScheduleExpeditionBtn = ({ ScheduleExpdition }) => {
  const [open, setOpen] = useState(false)
  const [startingAt, setStartingAt] = useState('')

  const handleInputChange = (event) => {
    const { value } = event.target;
    setStartingAt(value);
  };

  return (
    <CustomWidthTooltip title={<div onClick={(event) => event.stopPropagation()} style={{ background: '#F9CB26' }}>

      <div className='font-marker' style={{ fontSize: '1.5rem' }}>
        Schedule Expedition
      </div>
      <div className='flex flex-wrap align-center' style={{ gap: '0.3rem' }}>
        <div>
          Schedule Time (In your time zone):
        </div>
        <Input
          type={'datetime-local'}
          value={startingAt}
          onChange={handleInputChange}
        />
      </div>
      <ScheduleExpdition startingTime={startingAt} />
    </div>} placement="top" open={open} className='fortify__check-statsu__tooltip'>
      <Button style={{ fontSize: '0.8rem' }} onClick={() => setOpen(!open)}> Schedule EXPedition:</Button>
    </CustomWidthTooltip>
  )
}

