import React, { useContext, useEffect } from 'react'
import { MainContext } from '../App'
import Navbar from '../components/utility/Navbar'
import './../css/homePage.css'
import { Link } from 'react-router-dom'
import { useCheckMobileScreen } from '../javascript/utility'
import Preloader from '../components/utility/Preloader'
import lockIcon from './../assets/img/utility/icons/lock.svg'
export default function MainPage() {
    const { project, navbarTitle } = useContext(MainContext)
    const [, setNavbarTitle] = navbarTitle
    const isMobile = useCheckMobileScreen()
    useEffect(() => {
        setNavbarTitle('Zalez Expeditions')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (project) {
        return (
            <div className='home__body'>
                <Navbar />
                <div className='home__content-wrapper' style={{ margin: isMobile ? '0.2rem 1rem' : '0.2rem 1.5rem', background: `url(${project.images.mainPageBackground})` }}>
                    <div className='home__content' style={{ padding: isMobile ? '1rem 0.5rem' : '1rem 2rem' }}>
                        <div className='home__anchor-wrapper utility__info-box__cyan' style={{ background: '#00ffffa3', fontWeight: '500' }}>
                            <div className='home__anchor-container'>
                                <div>
                                    Get your Zalez Beachers army together, pack your bags and don't forget your compass! On this platform you'll be able to spend your hard earned $FANG to rank up your NFTs and gain some valuable resources.
                                </div>
                            </div>
                        </div>
                        <Link className='home__anchor-wrapper' to={'/fortify'}>

                            <div className='home__anchor-container'>
                                <div className='home__anchor-container-title font-marker'>
                                    Fortify & Assemble
                                </div>
                                <div>
                                    Train your Zalez Beachers to reach new ranks! Each area brings new levels, rewards and gets one step closer to returning Zalez Island to it's former paradise
                                </div>
                            </div>
                        </Link>
                        {/* <Link className='home__anchor-wrapper' to={'/expeditions'}> */}
                        <Link className='utility__info-box__yellow' to='/expeditions' style={{ position: 'relative' }}>
                            <div className='home__anchor-container'>
                                <div className='home__anchor-container-title font-marker'>
                                    Set off on an Expedition
                                </div>
                                <div>
                                    Send your experienced Zalez Beachers off on expeditions for your chance at fantastic rewards. The higher rank your NFT, the more opportunities and the bigger the reward!
                                </div>
                            </div>
                        </Link>

                        <Link className='home__anchor-wrapper' to={'/leaderboard'}>
                            <div className='home__anchor-container'>
                                <div className='home__anchor-container-title font-marker'>
                                    EXP Leaderboard
                                </div>
                                <div>
                                    See where you rank on the Leaderboard
                                </div>
                            </div>
                        </Link>

                        <Link className='home__anchor-wrapper' to={'https://stake.zalezlabs.xyz/'}>
                            <div className='home__anchor-container'>
                                <div className='home__anchor-container-title font-marker'>
                                    Accumulate Resources
                                </div>
                                <div>
                                    Stake your Zalez Beachers to earn $FANG!
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='utility__loader-container'>
                <Preloader />
            </div>
        )
    }
}
