import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress } from "@solana/spl-token";
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import config from "../config";
import { enqueueSnackbar } from "notistack";
import { sleep } from "./utility";

export const updatExpeditions = async (setExpeditions, publicKey, projectId) => {
    try {
        const req = await fetch(`${config.apiUrl}/get-expeditions`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey
            })
        })
        const reqJson = await req.json()
        if (reqJson?.status) setExpeditions(reqJson.data)
        else setExpeditions()
    } catch (err) {
        console.log(err)
        setExpeditions()
    }
}


export const buyExpGear = async (publicKey, project, quantity, priceOption, expGear, setDisableBtn, connection, signTransaction, setDisplaySignModal) => {
    try {
        if (!quantity) return enqueueSnackbar({ message: `Quantity cannot be less than 1`, variant: 'error' })
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')

        const costReq = await fetch(`${config.apiUrl}/get-price-quote`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: project.projectId,
                walletAddress: publicKey,
                quantity: quantity,
                optionId: priceOption.optionId,
                expGearId: expGear.id,
                nonceId,
                nonceSignature
            })
        })
        const costReqJson = await costReq.json()
        let signature;
        let tx = new Transaction()
        if (costReqJson?.status) {
            const payments = costReqJson.data.price

            for (const payment of payments) {
                if (payment.type === 'SOL') {
                    tx.add(SystemProgram.transfer({
                        fromPubkey: new PublicKey(publicKey),
                        toPubkey: new PublicKey(payment.toWallet),
                        lamports: payment.amount
                    }))
                }
                if (payment.type === 'SPL-TOKEN') {
                    const tokenPayment = payment.amount
                    const mintToken = new PublicKey(payment.data.tokenAddress)
                    const recipientAddress = new PublicKey(payment.toWallet)

                    const associatedTokenFrom = await getAssociatedTokenAddress(
                        mintToken,
                        publicKey
                    );
                    const fromAccount = await getAccount(connection, associatedTokenFrom);
                    const associatedTokenTo = await getAssociatedTokenAddress(
                        mintToken,
                        recipientAddress
                    );

                    if (!(await connection.getAccountInfo(associatedTokenTo))) {
                        const instruction = createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            recipientAddress,
                            mintToken
                        )
                        tx.add(instruction)
                    }
                    const instruction2 = createTransferInstruction(
                        fromAccount.address,
                        associatedTokenTo,
                        publicKey,
                        tokenPayment
                    )
                    tx.add(instruction2)
                }
            }
            const expGearToken = new PublicKey(expGear.data.tokenAddress)
            const associatedTokenTo = await getAssociatedTokenAddress(
                expGearToken,
                publicKey
            );
            if (!(await connection.getAccountInfo(associatedTokenTo))) {
                tx.add(
                    createAssociatedTokenAccountInstruction(
                        publicKey,
                        associatedTokenTo,
                        publicKey,
                        expGearToken
                    )
                )
            }

            let blockhash = await connection.getLatestBlockhash()
            tx.feePayer = publicKey
            tx.recentBlockhash = blockhash.blockhash
            const signed = await signTransaction(tx)
            signature = await connection.sendRawTransaction(signed.serialize())
            await connection.confirmTransaction(signature, 'processed')

            async function sendReq() {
                const req = await fetch(`${config.apiUrl}/buy-exp-gear`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        projectId: project.projectId,
                        walletAddress: publicKey,
                        quantity: quantity,
                        optionId: priceOption.optionId,
                        expGearId: expGear.id,
                        signature: signature,
                        quoteId: costReqJson.data.quoteId,
                        nonceId,
                        nonceSignature
                    })
                })
                const reqJson = await req.json()
                if (!reqJson.status && (reqJson.message === 'Transaction not found' || reqJson.message === 'Token Account not found')) {
                    const newReqJson = await sendReq()
                    return newReqJson
                }
                return reqJson
            }
            const reqJson = await sendReq()
            if (reqJson?.status) {
                enqueueSnackbar({ message: reqJson.message, variant: 'success' })
                setDisableBtn(false)
            } else {
                if (reqJson.data?.nonce) setDisplaySignModal(true)
                enqueueSnackbar({ message: reqJson.message, variant: 'error' })
                setDisableBtn(false)
            }

        } else {
            if (costReqJson.data.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: costReqJson.message, variant: 'error' })
            setDisableBtn(false)
            return
        }
    } catch (err) {
        console.log(err.toString())
        enqueueSnackbar({ message: err.toString() === 'TokenAccountNotFoundError' ? 'You dont have enough tokens to pay' : err.message || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}


export const startExpeditions = async (publicKey, projectId, nfts, expeditionId, setDisableBtn, connection, signTransaction, setChoosenNfts, refresh, setDisplaySignModal, setActiveExpedition) => {
    try {
        if (!nfts.filter(x => x).length) {
            return enqueueSnackbar({ message: `You need to choose NFT you want to send on mission`, variant: 'error' })
        }
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')
        const costReq = await fetch(`${config.apiUrl}/get-exp-costs`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                projectId: projectId,
                walletAddress: publicKey,
                nfts: nfts.filter(x => x).map(z => z.mintAddress),
                expeditionId: expeditionId,
                nonceId,
                nonceSignature
            })
        })
        const costReqJson = await costReq.json()
        let signature;
        let tx = new Transaction()
        if (costReqJson?.status) {
            for (const x of costReqJson.data) {
                if (x.type === 'SPL-TOKEN') {
                    const tokenPayment = x.amount
                    console.log(tokenPayment, x)
                    const mintToken = new PublicKey(x.data.tokenAddress)
                    const recipientAddress = new PublicKey(x.feesWallet)
                    const associatedTokenFrom = await getAssociatedTokenAddress(
                        mintToken,
                        publicKey
                    );
                    if (!(await connection.getAccountInfo(associatedTokenFrom))) {
                        enqueueSnackbar({ message: `You dont own any ${x.name} to start this mission`, variant: 'error' })
                        setDisableBtn(false)
                        return
                    }
                    const fromAccount = await getAccount(connection, associatedTokenFrom);
                    const associatedTokenTo = await getAssociatedTokenAddress(
                        mintToken,
                        recipientAddress
                    );

                    if (!(await connection.getAccountInfo(associatedTokenTo))) {
                        const instruction = createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            recipientAddress,
                            mintToken
                        )
                        tx.add(instruction)
                    }
                    const instruction2 = createTransferInstruction(
                        fromAccount.address,
                        associatedTokenTo,
                        publicKey,
                        Math.ceil((tokenPayment * (x.data.decimals || LAMPORTS_PER_SOL)))
                    )

                    tx.add(instruction2)
                }
                if (x.type === 'SOL') {
                    tx.add(SystemProgram.transfer({
                        fromPubkey: publicKey,
                        toPubkey: new PublicKey(x.feesWallet),
                        lamports: Math.ceil(x.amount * LAMPORTS_PER_SOL)
                    }))
                }
            }
            let blockhash = await connection.getLatestBlockhash()
            tx.feePayer = publicKey
            tx.recentBlockhash = blockhash.blockhash
            const signed = await signTransaction(tx)
            signature = await connection.sendRawTransaction(signed.serialize())
            await connection.confirmTransaction(signature, 'processed')
        } else {
            if (costReqJson.data.nonce) {
                setDisplaySignModal(true)
                setActiveExpedition()
            }
            enqueueSnackbar({ message: costReqJson.message, variant: 'error' })
            setDisableBtn(false)
            return
        }
        async function sendReq() {
            const req = await fetch(`${config.apiUrl}/start-expedition`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: projectId,
                    walletAddress: publicKey,
                    nfts: nfts.filter(x => x).map(z => z.mintAddress),
                    expeditionId: expeditionId,
                    signature: signature,
                    nonceId,
                    nonceSignature
                })
            })
            const reqJson = await req.json()
            if (!reqJson.status && reqJson.message === 'Transaction not found') {
                const newReqJson = await sendReq()
                return newReqJson
            }
            return reqJson
        }
        const reqJson = await sendReq()
        if (reqJson?.status) {
            refresh()
            enqueueSnackbar({ message: reqJson.message, variant: 'success' })
            setChoosenNfts([
                undefined, undefined, undefined, undefined
            ])
            setDisableBtn(false)
        } else {
            if (reqJson.data?.nonce) setDisplaySignModal(true)
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        console.log(err)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}


export const claimExpeditionReward = async (publicKey, projectId, levelId, setDisableBtn, setClaimedReward, refresh, connection, signTransaction, setDisplaySignModal, setActiveExpedition) => {
    try {
        setDisableBtn(true)
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')
        let claimedRewards = []
        async function sendReq() {
            const req = await fetch(`${config.apiUrl}/claim-expedition-rewards`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: projectId,
                    walletAddress: publicKey,
                    expeditionId: levelId,
                    nonceId, nonceSignature
                })
            })
            const reqJson = await req.json()
            if (reqJson.status && !reqJson.data?.claimed) claimedRewards = claimedRewards.concat(reqJson.data)
            if (reqJson.message === 'Token Account not found') {
                const mintToken = new PublicKey(reqJson.data.tokenAddress)
                const associatedTokenTo = await getAssociatedTokenAddress(
                    mintToken,
                    publicKey
                )
                if (!(await connection.getAccountInfo(associatedTokenTo))) {
                    const tx = new Transaction()
                    tx.add(
                        createAssociatedTokenAccountInstruction(
                            publicKey,
                            associatedTokenTo,
                            publicKey,
                            mintToken
                        )
                    )
                    const blockHash = await connection.getLatestBlockhash();
                    tx.feePayer = publicKey;
                    tx.recentBlockhash = blockHash.blockhash;

                    const signed = await signTransaction(tx);
                    const sign = await connection.sendRawTransaction(signed.serialize());

                    await connection.confirmTransaction(sign, 'processed');
                }
                await sleep(5000)
                const newReqJson = await sendReq()
                return newReqJson
            }
            return reqJson
        }
        const reqJson = await sendReq()

        if (reqJson?.status) {
            setClaimedReward(claimedRewards)
            refresh()
            setDisableBtn(false)
        } else {
            if (reqJson.data?.nonce) {
                setDisplaySignModal(true)
                setActiveExpedition()
            }
            enqueueSnackbar({ message: reqJson.message, variant: 'error' })
            setDisableBtn(false)
        }
    } catch (err) {
        console.log(err)
        enqueueSnackbar({ message: err.message || `An unknown error occured`, variant: 'error' })
        setDisableBtn(false)
    }
}


