import React, { useContext, useEffect, useState } from 'react'
import Preloader from './../utility/Preloader.js'
import { MainContext } from '../../App.js'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { claimExpeditionReward, startExpeditions, updatExpeditions } from '../../javascript/expeditions.js'
import prettyMilliseconds from 'pretty-ms'
import { Timer } from '../utility/Timer.js'
import { NFTSelect } from './SelectNFTs.js'
import { Button, IconButton, Tooltip, styled, tooltipClasses } from '@mui/material'
import { useCheckMobileScreen } from '../../javascript/utility.js'
import { MdDelete } from 'react-icons/md'
import { deleteExpedition } from '../../javascript/dashboard.js'
export default function ExpList() {
  const { project, expeditionsState } = useContext(MainContext)
  const { publicKey } = useWallet()
  const [expeditions, setExpeditions] = expeditionsState
  const [choosenNfts, setChoosenNfts] = useState([])
  const [activeExpedition, setActiveExpedition] = useState()
  useEffect(() => {
    updatExpeditions(setExpeditions, publicKey, project.projectId)
  }, [project, publicKey, setExpeditions])

  return (
    <div className='utility__info-box__yellow utility__info-box__yellow-no-hover exp__yellow-container'>
      <div className='flex flex-column'>
        <div className='font-marker' style={{ textAlign: 'center', fontSize: '1.5rem', width: '100%', padding: '1rem 0px 0px 0px' }}>
          Select your expedition
        </div>
        <ExpeditionModal choosenNfts={choosenNfts} setChoosenNfts={setChoosenNfts} activeExpedition={activeExpedition} setActiveExpedition={setActiveExpedition} />
        <div>
          {!expeditions ? <div className='flex flex-row justify-center align-center'><Preloader /></div> :
            !expeditions.length ? (<div style={{ fontSize: '1.3rem', minHeight: '100%' }} className='flex flex-row align-center justify-center'>
              There are no active expeditions
            </div>) :
              (<div className='flex flex-column' style={{ padding: '1rem 1rem', gap: '0.5rem' }}>
                {expeditions.map((x, i) => {
                  return (<div key={i} className='exp__cyan-container flex flex-row flex-wrap justify-around'>
                    <div className='font-marker' style={{ textAlign: 'center', fontSize: '1.3rem', flex: 1 }}>
                      {x.label}
                    </div>
                    <button className='exp__yellow-btn font-marker' style={{ marginRight: '0.5rem' }} onClick={() => setActiveExpedition(x)}>MORE INFO</button>
                  </div>)
                })}
              </div>)}
        </div>
      </div>
    </div>
  )
}


const ExpeditionModal = ({ activeExpedition, setActiveExpedition, choosenNfts, setChoosenNfts }) => {
  const { signTransaction, publicKey } = useWallet()
  const { project, refresh, signModalState } = useContext(MainContext)
  const [, setDisplaySignModal] = signModalState
  const [disableDeleteBtn, setDisableDeleteBtn] = useState(false)
  const { connection } = useConnection()
  const [claimedReward, setClaimedReward] = useState()
  const [disableStartExpedition, setDisableStartExpedition] = useState(false)
  const isMobile = useCheckMobileScreen()
  if (claimedReward) {
    return (<div className='modal-wrapper'>
      <div className='modal-bg' onClick={() => setClaimedReward()}></div>
      <div className='modal-container'>
        <div className='modal' style={{ padding: '0.5rem 2rem 0.5rem 0.5rem', textAlign: 'left' }}>
          <div style={{ fontSize: '1.3rem', padding: '0px 0px 0px 1rem' }} className='font-marker'>Congratulations! 🎉 You recieved:</div>
          {claimedReward.map(x => (<div>{`+ ${x.data?.message || `${x.data?.symbol || x.data?.name || x.type}: ${x.amount}`}`}</div>))}
          <a target='_blank' className='flex flex-row justify-center' style={{ textDecoration: 'none' }} href={`https://twitter.com/intent/tweet?text=I just won ${claimedReward.filter(x => !x.data?.message).map(x => `${x.amount} ${x.data?.symbol || x.data?.name || x.type}`).join(', ')} from Zalez EXPeditions! Train your Zalez to be ready for the next EXPedition https://expedition.zalezlabs.xyz/`}>
            <Button sx={{
              color: 'white',
              background: '#1986d2',
              width: 'fit-content',
              textTransform: 'none',
              '&:hover': {
                background: '#1976d2',
              }
            }}>Share on Twitter
            </Button>
          </a>
        </div>
      </div>
    </div>)
  } else if (activeExpedition) {
    return (
      <div className='modal-wrapper'>
        <div className='modal-bg' onClick={() => setActiveExpedition()}></div>
        <div className='modal-container'>
          <div className='modal' style={{ padding: '0px', border: '3px solid black' }}>

            <div className='flex flex-row flex-wrap justify-center'>
              <div className='flex flex-column' style={{ gap: '1rem', width: '400px', maxWidth: '90vw', padding: '1rem', borderRight: !isMobile ? '3px solid black' : '' }}>
                {project.ownerAddress === publicKey?.toString() && (
                  <div className='flex flex-row'>
                    <IconButton
                      disabled={disableDeleteBtn}
                      style={{ color: 'black' }}
                      onClick={() =>
                        deleteExpedition(
                          setDisableDeleteBtn,
                          project.projectId,
                          publicKey,
                          setDisplaySignModal,
                          activeExpedition.id
                        )
                      }
                    >
                      <MdDelete />
                    </IconButton>
                  </div>
                )}
                <div className='font-marker' style={{ fontSize: '1.5rem' }}>
                  {activeExpedition.label}
                </div>
                <div className='exp__brown-container' style={{ minHeight: '100px' }}>
                  {activeExpedition.description}
                </div>
                <div className='exp__brown-container' style={{ minHeight: '100px' }}>
                  <div className='font-marker'>Requirements:</div>
                  <div className='flex flex-row justify-between flex-wrap'>
                    <div className='exp__requirement'>
                      <div>{activeExpedition.createdAt > Date.now() ? 'Starting In ' : 'Time to Complete'}</div>
                      <div className='flex flex-row value'><Timer deadline={new Date(activeExpedition.createdAt > Date.now() ? activeExpedition.createdAt : activeExpedition.endingTime)} /></div>
                    </div>
                    {activeExpedition.requirements.map(x => {
                      return {
                        title:
                          x.type === 'min-level' ? 'Minimum Level' :
                            x.type === 'exp-gear' ? 'Expedition Kits' :
                              x.type === 'fees' ? 'Expedition Fee' :
                                'Required',
                        value: x.type === 'min-level' ? x.data.level :
                          x.type === 'exp-gear' ? x.data.expGears.map(x => `${x.amount} ${x.name}`).join(', ') :
                            x.type === 'fees' ? x.data.amount.map(y => `${y.amount} ${y.type === 'SOL' ? 'SOL' : y.data.symbol}`).join(', ') :
                              'Required',
                      }
                    }).map((x, i) => {
                      return (
                        <div key={i} className='exp__requirement'>
                          <div>{x.title}</div>
                          <div className='value'>{x.value}</div>
                        </div>
                      )
                    })}
                    <div className='exp__requirement'>
                      <div>Available Spots/Wallet</div>
                      <div className='flex flex-row value'>{activeExpedition.data.maxExpPerWallet}</div>
                    </div>

                    <div className='exp__requirement'>
                      <div>Total Available Spots</div>
                      <div className='flex flex-row value'>{activeExpedition.data.spotsAvailable}</div>
                    </div>
                    <div className='exp__requirement'>
                      <div>Remaining Spots</div>
                      <div className='flex flex-row value'>{activeExpedition.data.spotsAvailable - activeExpedition.data.spotsFilled < 0 ? 0 : activeExpedition.data.spotsAvailable - activeExpedition.data.spotsFilled}</div>
                    </div>

                    {activeExpedition.data.reoccuring && (
                      <>
                        <div className='exp__requirement'>
                          <div>Recurring Missions</div>
                          <div className='flex flex-row value'>{activeExpedition.data.occurences || 'Infinite'}</div>
                        </div>
                        {activeExpedition.data.occurences && (
                          <div className='exp__requirement'>
                            <div>Remaining Recurring Missions</div>
                            <div className='flex flex-row value'>{activeExpedition.data.occurences - (activeExpedition.data.occuredTimes || 0)}</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className='exp__brown-container' style={{ minHeight: '200px' }}>
                  <div className='font-marker'>Rewards and Probabilities:</div>

                  {activeExpedition.rewards.map((x, i) => {
                    const maxLength = 50
                    const dotsCount = maxLength - `${x.amount !== 1 ? `${x.amount} ` : ''}${(['SOL', 'EXP'].includes(x.type) ? x.type : x.data.name || x.data.symbol)}`.length;
                    const dots = '.'.repeat(dotsCount);

                    return <div>{x.amount !== 1 ? `${x.amount} ` : ''}{(['SOL', 'EXP'].includes(x.type) ? x.type : x.data.name || x.data.symbol)}{dots}{x.probability}%</div>;
                  })}
                </div>
                <div className='flex flex-row justify-right' style={{ padding: '0.8rem 0px 0px 0px', gap: '1rem' }}>
                  <ClaimRewards activeExpedition={activeExpedition} setClaimedReward={setClaimedReward} setActiveExpedition={setActiveExpedition}/>
                  <CheckStatus activeExpedition={activeExpedition} />
                  <Button disabled={disableStartExpedition} className='fortify__green-btn' onClick={() => startExpeditions(publicKey, project.projectId, choosenNfts, activeExpedition.id, setDisableStartExpedition, connection, signTransaction, setChoosenNfts, refresh, setDisplaySignModal, setActiveExpedition)}>Start Expedition</Button>
                </div>
              </div>
              <NFTSelect choosenNfts={choosenNfts} setChoosenNfts={setChoosenNfts} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}



const CheckStatus = ({ activeExpedition }) => {
  const [open, setOpen] = useState(false)
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '80vw',
      padding: '0.5rem 1rem'
    },
  });

  const { missions } = useContext(MainContext)
  if (missions && missions.find(z => z.levelId === activeExpedition.id && z.areaId === activeExpedition.areaId)) {
    return (
      <CustomWidthTooltip title={<div onClick={() => setOpen(!open)}>
        <div style={{ fontSize: '1.4rem', paddingBottom: '0.3rem' }}>
          Level Status:
        </div>
        <div>
          Number of Active Missions: {missions.filter(z => z.levelId === activeExpedition.id && z.areaId === activeExpedition.areaId).length}
        </div>
        <div>
          Total Zalez in Level:  {missions.filter(z => z.levelId === activeExpedition.id && z.areaId === activeExpedition.areaId).reduce((a, b) => a + b.nfts.length, 0)}
        </div>
        {missions && missions.find(z => z.levelId === activeExpedition.id && z.areaId === activeExpedition.areaId && z.endingAt > Date.now()) && (
          <div>
            Estimated Time left to complete: {prettyMilliseconds((missions.filter(z => z.levelId === activeExpedition.id && z.areaId === activeExpedition.areaId && z.endingAt > Date.now()).sort((a, b) => b.endingAt - a.endingAt))[0].endingAt - Date.now(), { verbose: true })}
          </div>
        )}
      </div>} placement="top" open={open} onClose={() => setOpen(false)} style={{ backgroundColor: '#808181' }} className='fortify__check-statsu__tooltip' arrow>
        <Button onClick={() => setOpen(!open)} className='fortify__green-btn' style={{ '--bg-color': '#5D615C' }}>Check Status</Button>
      </CustomWidthTooltip>
    )
  }
}

const ClaimRewards = ({ activeExpedition, setClaimedReward, setActiveExpedition }) => {
  const { missions, refresh, signModalState } = useContext(MainContext)
  const [, setDisplaySignModal] = signModalState
  const [disableBtn, setDisableBtn] = useState(false)
  const { publicKey, signTransaction } = useWallet()
  const { connection } = useConnection()
  if (missions && missions.find(z => z.levelId === activeExpedition.id && z.rewards.length)) {
    return (
      <Button disabled={disableBtn} className='fortify__green-btn' onClick={() => claimExpeditionReward(publicKey, activeExpedition.projectId, activeExpedition.id, setDisableBtn, setClaimedReward, refresh, connection, signTransaction, setDisplaySignModal, setActiveExpedition)}>Claim Rewards</Button>
    )
  }
}
