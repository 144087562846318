import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Button } from '@mui/material';
import { useCheckMobileScreen } from '../../javascript/utility';
import { MainContext } from '../../App';
import HomeIcon from './../../assets/img/utility/icons/house_damage.svg'
import PrestigeIcon from './../../assets/img/utility/icons/financial_growth.svg'
import GlobeIcon from './../../assets/img/utility/icons/earth_globe.svg'
import CoinsIcon from './../../assets/img/utility/icons/coins.svg'
import LeaderboardIcon from './../../assets/img/utility/icons/leaderboard.svg'
import StakeIcon from './../../assets/img/utility/icons/cart_shopping.svg'
import lockIcon from './../../assets/img/utility/icons/lock.svg'
import Logo from './../../assets/img/logo.png'

import { Link } from 'react-router-dom';

export default function SideBar({ openedState }) {
    const [opened, setOpened] = openedState;
    const [linkStyle, setStyle] = useState()
    const { project } = useContext(MainContext)
    const isMobile = useCheckMobileScreen()
    useEffect(() => {
        if (isMobile) setStyle({ maxWidth: '80vw', width: '4rem' })
        else setStyle({ maxWidth: '30vw' })
    }, [isMobile])
    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }
                setOpened(open);
            };

    const list = () => (
        <Box
            sx={{ width: 100, maxWidth: isMobile ? '80vw' : '30vw', height: '100%', backgroundColor: '#29CFC4', minHeight: '100vh', padding: '0px 0.2rem', display: 'flex', flexFlow: 'column', gap: '0.5rem' }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <div className='utility__navbar-logo' style={{ ...linkStyle, margin: 'auto' }} >
                <img src={Logo} alt={project.name} style={{ width: '100%', margin: 'auto', borderRadius: '100px' }} />
            </div>

            <Link to={'/'} className='utility__navbar-logo utility__sidebar-btn' style={{ ...linkStyle, margin: 'auto' }} >
                <img src={HomeIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                <div>Home page</div>
            </Link>
            <Link to={'/prestige'} className='utility__navbar-logo utility__sidebar-btn' style={{ ...linkStyle, margin: 'auto' }} >
                <img src={PrestigeIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                <div>Prestige your Zalez</div>
            </Link>
            <Link to={'/expeditions'} className='utility__navbar-logo utility__sidebar-btn' style={{ ...linkStyle, margin: 'auto' }}  >
                <img src={GlobeIcon} alt={project.name} style={{ width: '70%', margin: 'auto'  }} />
                <div>Set off on an Expedition</div>
            </Link>
            <Link to={'/leaderboard'} className='utility__navbar-logo utility__sidebar-btn' style={{ ...linkStyle, margin: 'auto' }} >
                <img src={LeaderboardIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                <div>Leaderboard</div>
            </Link>

            <Link to={'https://stake.zalezlabs.xyz'} target='_blank' className='utility__navbar-logo utility__sidebar-btn' style={{ ...linkStyle, margin: 'auto' }} >
                <img src={CoinsIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                <div>Claim earned resources</div>
            </Link>
            <Link to={'https://famousfoxes.com/tokenmarket/RAyzRt4zj4DxV3G4GkCGYcKDDEPeeSFX3Tfg51ysQDK'} target='_blank' className='utility__navbar-logo utility__sidebar-btn' style={{ ...linkStyle, margin: 'auto' }} >
                <img src={StakeIcon} alt={project.name} style={{ width: '70%', margin: 'auto' }} />
                <div>Buy Resources</div>
            </Link>
        </Box>
    );


    return (
        <>
            <SwipeableDrawer
                anchor={"left"}
                open={opened}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list("left")}
            </SwipeableDrawer>
        </>
    )
}

