import { enqueueSnackbar } from "notistack";
import config from "../config";
import { LAMPORTS_PER_SOL, PublicKey, Transaction } from "@solana/web3.js";
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress } from "@solana/spl-token";

export const prestige = async (publicKey, projectId, connection, project, signTransaction, refresh, setDisableBtn, setDisplaySignModal) => {
    try {
        setDisableBtn(true)
        let answers = document.getElementById('prestige__select-nft-form')
        const nftsChosenArray = []

        if(!answers) {
            setDisableBtn(false)
            return enqueueSnackbar({ message: 'No NFT selected', variant: 'error' })
        }
        for (var i = 0; i < answers.length; i++) {
            if (answers.elements[i].type !== 'submit') {
                nftsChosenArray.push({ mint: answers.elements[i].id, checked: answers.elements[i].checked });
            }
        }
        const nonceId = localStorage.getItem('nonceId')
        const nonceSignature = localStorage.getItem('nonceSignature')
        let nftsToPrestige = nftsChosenArray.filter(x => x.checked === true)
        if (!nftsToPrestige.length) {
            setDisableBtn(false)
            enqueueSnackbar({ message: 'No NFT selected', variant: 'error' })
        } else {
            const nftsReq = nftsToPrestige.map(z => z.mint.replace('prestige__nft-select-', ''))
            const req = await fetch(`${config.apiUrl}/check-prestige-cost`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectId: projectId,
                    walletAddress: publicKey,
                    nfts: nftsReq,
                    nonceId, 
                    nonceSignature
                })
            })
            const reqJson = await req.json()
            if (reqJson?.status) {
                let tx = new Transaction()
                let signature;
                for (const x of reqJson.data) {
                    if (x.type === 'token') {
                        const tokenPayment = x.data.amount
                        const mintToken = new PublicKey(x.data.tokenAddress)
                        const recipientAddress = new PublicKey(project.feesWallet)

                        const associatedTokenFrom = await getAssociatedTokenAddress(
                            mintToken,
                            publicKey
                        );
                        const fromAccount = await getAccount(connection, associatedTokenFrom);
                        const associatedTokenTo = await getAssociatedTokenAddress(
                            mintToken,
                            recipientAddress
                        );

                        if (!(await connection.getAccountInfo(associatedTokenTo))) {
                            const instruction = createAssociatedTokenAccountInstruction(
                                publicKey,
                                associatedTokenTo,
                                recipientAddress,
                                mintToken
                            )
                            tx.add(instruction)
                        }
                        const instruction2 = createTransferInstruction(
                            fromAccount.address,
                            associatedTokenTo,
                            publicKey,
                            Math.round(tokenPayment * (x.data.decimals || LAMPORTS_PER_SOL))
                        )
                        tx.add(instruction2)
                    }
                }
                let blockhash = await connection.getLatestBlockhash()
                tx.feePayer = publicKey
                tx.recentBlockhash = blockhash.blockhash
                const signed = await signTransaction(tx)
                signature = await connection.sendRawTransaction(signed.serialize())
                await connection.confirmTransaction(signature, 'processed')

                async function sendPrestigeReq() {
                    const prestigeReq = await fetch(`${config.apiUrl}/prestige`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            projectId: projectId,
                            walletAddress: publicKey,
                            nfts: nftsReq,
                            signature: signature,
                            nonceId,
                            nonceSignature
                        })
                    })
                    const prestigeReqJson = await prestigeReq.json()
                    if (!prestigeReqJson.status && prestigeReqJson.message === 'Transaction not found') {
                        const newPrestigeReqJson = await sendPrestigeReq()
                        return newPrestigeReqJson
                    }
                    return prestigeReqJson
                }
                const prestigeReqJson = await sendPrestigeReq()
                if(!prestigeReqJson.status && prestigeReqJson.data.nonce) setDisplaySignModal(true) 
                refresh()
                enqueueSnackbar({ message: prestigeReqJson.message, variant: prestigeReqJson.status ? 'success' : 'error' })
                setDisableBtn(false)
                return
            } else {
                if (reqJson.data?.nonce) setDisplaySignModal(true)
                enqueueSnackbar({ message: reqJson.message, variant: 'error' })
                setDisableBtn(false)
                return
            }
        }
    } catch (err) {
        console.log(err)
        enqueueSnackbar({message: err.message || `An unknown error occured`, variant: 'error'})
        setDisableBtn(false)
    }
}