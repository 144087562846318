import React, { useContext, useEffect, useRef, useState } from 'react'
import { sleep, useCheckMobileScreen } from '../javascript/utility'
import Preloader from '../components/utility/Preloader'
import config from '../config'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MainContext } from '../App';


export default function EXPLeaderboard() {
    const [NFTs, setNFTs] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(0);
    const isMobile = useCheckMobileScreen()
    const elementRef = useRef(null);
    const {project} = useContext(MainContext)
    useEffect(() => {
        fetchData();
        const element = elementRef.current;
        if (element) {
            element.addEventListener("scroll", handleScroll);
        }
    }, [elementRef]);

    const handleScroll = () => {
        const element = elementRef.current;
        if (element) {
            const { scrollTop, scrollHeight, clientHeight } = element;
            const isScrolledToBottom = (Math.floor(scrollTop + clientHeight) + 1) >= scrollHeight;

            if (isScrolledToBottom && !isFetching) {
                setIsFetching(true);
            }
        };
    }
    const fetchData = async () => {
        await sleep(1000)
        const result = await fetch(`${config.apiUrl}/get-leaderboard`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                projectId: project.projectId,
                offset: page
            })
        });
        const resultJson = await result.json()
        const data = resultJson.data.nfts
        setPage(page + 10);
        setNFTs(() => {
            return [...NFTs, ...data.filter(x => !NFTs.find(z => z.mintAddress === x.mintAddress))];
        });
    };

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreNFTs();
    }, [isFetching]);

    const fetchMoreNFTs = async () => {
        await fetchData();
        setIsFetching(false);
    };

    return (
        <div className='home__content-wrapper' ref={elementRef} style={{ margin: '0.2rem 1rem', }}>
            <div className='home__content' style={{ padding: isMobile ? '1rem 0px' : '1rem 2rem' }}>

                <div>

                    {NFTs && NFTs.sort((a, b) => b.exp - a.exp).map((x, i) => {
                        return (
                            <NFTOnLeaderboard key={i} x={x} />
                        )
                    })}
                    {(isFetching || !NFTs.length) && (<Preloader style={{ margin: 'auto' }} />)}
                </div>
            </div>
        </div>

    );
};


const NFTOnLeaderboard = ({ x }) => {
    const isMobile = useCheckMobileScreen()
    return (
        <div>
            <div className='utility__info-box__yellow utility__info-box__cyan' style={{ minHeight: '40px', margin: isMobile ? '0px 1rem' : 'inherit' }}>
                <div className='home__anchor-container' style={{ justifyContent: 'space-between', flexFlow: 'row wrap', width: '100%', display: 'flex', alignItems: 'center' }}>
                    <LazyLoadImage src={x.image} style={{ width: '100px', maxWidth: '20vw', borderRadius: '100px', aspectRatio: '1 / 1' }} />

                    <div className='flex flex-column justify-center align-center'>
                        <div className='font-marker' style={{ fontSize: isMobile ? '1rem' : '1.5rem' }}>
                            EXP
                        </div>
                        <div>
                            {Math.round(x.exp)}
                        </div>
                    </div>

                    <div className='fortify__exp-leaderboard__level-text' style={{ fontSize: isMobile ? '1rem' : '1.5rem' }}>
                        Level {x.level}
                    </div>
                </div>
            </div>

        </div>
    )

}
