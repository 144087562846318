import prettyMilliseconds from 'pretty-ms'
import React, { useContext, useEffect, useState } from 'react'
import RequirementImg from './../../assets/img/utility/icons/requirement.svg'
import RewardsImg from './../../assets/img/utility/icons/rewards.svg'
import BonusImg from './../../assets/img/utility/icons/bonus.svg'
import TickImg from './../../assets/img/utility/icons/verified.svg'
import PlusImg from './../../assets/img/utility/icons/plusSign.svg'
import { useCheckMobileScreen } from '../../javascript/utility'
import { Button, Popover, Tooltip, styled, tooltipClasses } from '@mui/material'
import { MainContext } from '../../App'
import InfoIcon from './../../assets/img/utility/icons/info.svg'
import Preloader from '../utility/Preloader'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { beginJourney, cancelMissions, claimRewards, updateColors } from '../../javascript/fortify'
import { LazyLoadImage } from 'react-lazy-load-image-component'
export default function Level({ displayLevel, setDisplayLevel }) {
    const isMobile = useCheckMobileScreen()
    const [choosenNfts, setChoosenNfts] = useState()
    const { signTransaction, publicKey } = useWallet()
    const { refresh, project, levels, signModalState } = useContext(MainContext)
    const [, setDisplaySignModal] = signModalState
    const { connection } = useConnection()
    const [disableBeginJourney, setDisableBeginJourney] = useState(false)
    const [claimedReward, setClaimedReward] = useState()
    const [bonuses, setBonuses] = useState({})
    useEffect(() => {
        if (choosenNfts && choosenNfts.filter(x => x) && project && displayLevel) {
            let levelWithoutFormat = levels.find(z => z.id === displayLevel.id && z.areaId === displayLevel.areaId)
            if (levelWithoutFormat) {
                let newBonsues = {}
                let costReduction2 = levelWithoutFormat.bonuses.find(z => z.bonusRewards.find(y => y.rewardType === 'cost-reduction') && z.type !== 'trait-category')
                if (costReduction2) {
                    let costReduction = costReduction2.bonusRewards.find(y => y.rewardType === 'cost-reduction')
                    if (costReduction2.type === 'same-faction') {
                        if (project.factions.find(x => choosenNfts.filter(z => z && x.traits.find(s => z.attributes.find(trait => {
                            return trait.value === s.value && trait.trait_type === s.trait_category && s.collectionId === z.collectionId
                        }))).length >= costReduction2.numberOfNfts)) {
                            newBonsues.tokenAmount = costReduction.amount
                        }
                    }
                }

                let costReduction3 = levelWithoutFormat.bonuses.find(z => z.bonusRewards.find(y => y.rewardType === 'cost-reduction') && z.type === 'trait-category')
                if (costReduction3) {
                    let costReduction4 = costReduction3.bonusRewards.find(y => y.rewardType === 'cost-reduction')
                    if (costReduction3.type === 'trait-category') {
                        if (choosenNfts.filter(z => z && z.attributes.find(trait => {
                            return costReduction3.data.traitCategories.includes(trait.trait_type)
                        })).length >= costReduction3.numberOfNfts) {
                            newBonsues.tokenAmount = costReduction4.amount
                        }
                    }
                }



                let expboost2 = levelWithoutFormat.bonuses.find(z => z.bonusRewards.find(y => y.rewardType === 'exp-boost') && z.type !== 'trait-category')
                if (expboost2) {
                    let expboost = expboost2.bonusRewards.find(y => y.rewardType === 'exp-boost')
                    if (expboost2.type === 'same-faction') {
                        if (project.factions.find(x => choosenNfts.filter(z => z && x.traits.find(s => z.attributes.find(trait => {
                            return trait.value === s.value && trait.trait_type === s.trait_category && s.collectionId === z.collectionId
                        }))).length >= expboost2.numberOfNfts)) {
                            newBonsues.rewardBoost = expboost.amount
                        }
                    }
                }


                let expboost3 = levelWithoutFormat.bonuses.find(z => z.bonusRewards.find(y => y.rewardType === 'exp-boost') && z.type === 'trait-category')
                if (expboost3) {
                    let expboost4 = expboost3.bonusRewards.find(y => y.rewardType === 'exp-boost')
                    if (expboost3.type === 'trait-category') {
                        if (choosenNfts.filter(z => z && z.attributes.find(trait => {
                            return expboost3.data.traitCategories.includes(trait.trait_type)
                        })).length >= expboost3.numberOfNfts) {
                            newBonsues.rewardBoost = expboost4.amount
                        }
                    }
                }

                setBonuses(newBonsues)
            } else {
                setBonuses({})
            }
        } else {
            setBonuses({})
        }
    }, [choosenNfts, displayLevel, levels, project])
    useEffect(() => {
        setChoosenNfts([
            undefined, undefined, undefined, undefined
        ])
    }, [])
    useEffect(() => {
        setChoosenNfts([
            undefined, undefined, undefined, undefined
        ])
    }, [displayLevel?.id])
    if (displayLevel && choosenNfts) {
        return (
            <div className='fortify__area__level-wrapper'>
                {claimedReward && (
                    <div className='modal-wrapper'>
                        <div className='modal-bg' onClick={() => setClaimedReward()}></div>
                        <div className='modal-container'>
                            <div className='modal' style={{ padding: '0.5rem 2rem 0.5rem 0.5rem' }}>
                                <div style={{ fontSize: '1.3rem', padding: '0px 0px 0px 1rem' }} className='font-marker'>Congratulations! 🎉 You recieved:</div>
                                {claimedReward.map(x => {
                                    if (x.type === 'exp') return (<div>{`+ EXP: ${x.data.exp}`}</div>)
                                    if (x.type === 'expedition-gear') return (<div>{`+ Expedition Gear: ${x.data.amount} ${x.data.name}`}</div>)
                                    return <div>{x}</div>
                                })}
                            </div>
                        </div>
                    </div>
                )}

                <div className='fortify__area__level-bg' onClick={() => setDisplayLevel()} ></div>
                <div className='fortify__area__level-container' style={{ backgroundImage: `url(${displayLevel.data?.images?.background})` }}>
                    <div className='fortify__area__level-flex'>
                        <div className='fortify__area__level-info'>
                            <div className='font-marker' style={{ fontSize: '1.5rem' }}>
                                {displayLevel.label}
                            </div>
                            <div style={{ fontSize: '0.8rem', whiteSpace: 'pre-line' }}>
                                {displayLevel.description}
                            </div>

                            <div className='flex flex-row flex-wrap align-center' style={{ margin: '0.5rem 0.2rem', gap: '0.2rem' }}>
                                <div className='w-700'>
                                    Estimated Mission Time:
                                </div>
                                <div>
                                    {prettyMilliseconds(displayLevel.time, { verbose: true })}
                                </div>
                            </div>
                            <div className='flex flex-column align-center'>
                                <div className='flex flex-row justify-center' style={{ width: '100%', margin: '0.5rem 0px', gap: '0.5rem' }}>
                                    <div>
                                        <img src={RequirementImg} alt='Requirement' style={{ width: isMobile ? '2rem' : '3rem' }} />
                                    </div>
                                    <div style={{ width: '200px', minWidth: '80%', maxWidth: '85%', textAlign: 'left' }}>
                                        <SeparationBar content={<RequirementInfo />} />
                                        <div className='w-700'>
                                            Requirements:
                                        </div>
                                        {displayLevel.requirements.map((x, i) => {
                                            let displayLevelInArray = levels.find(z => z.id === displayLevel.id)
                                            if (displayLevelInArray) {
                                                let requirementInLevel = displayLevelInArray.requirements.find((p, s) => s === i)
                                                if (bonuses.tokenAmount && requirementInLevel.type === 'token') {
                                                    return (
                                                        <div key={i} style={{ paddingLeft: '1rem', fontSize: '0.8rem', display: 'flex', flexFlow: 'row wrap', gap: '0.2rem' }}>
                                                            <div style={{ position: 'relative' }}>
                                                                <div className='flex flex-row' style={{ flexFlow: 'row wrap', gap: '0.2rem' }}>
                                                                    {requirementInLevel.data.tokenSymbol}:
                                                                    <div style={{ position: 'relative' }}>
                                                                        {requirementInLevel.data.tokenAmount}
                                                                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                                                            <div className='flex flex-row align-center' style={{ height: '100%', width: '100%' }}>
                                                                                <div style={{ width: '100%', height: '2px', background: 'rgb(248, 218, 115)' }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-wrap justify-center'>
                                                                <div style={{ color: "rgb(248, 218, 115)" }}>{`>>`}</div> {(100 - bonuses.tokenAmount) / 100 * requirementInLevel.data.tokenAmount}
                                                                <div className='flex flex-wrap justify-center' style={{ width: '8px', alignItems: 'baseline' }}>
                                                                    <img style={{ width: '8px' }} src={TickImg} alt="Verified-check" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                            return (
                                                <div key={i} style={{ paddingLeft: '1rem', fontSize: '0.8rem' }}>
                                                    {x}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>


                                <div className='flex flex-row justify-center' style={{ width: '100%', margin: '0.5rem 0px', gap: '0.5rem' }}>
                                    <div>
                                        <img src={RewardsImg} alt='Requirement' style={{ width: isMobile ? '2rem' : '3rem' }} />
                                    </div>
                                    <div style={{ width: '200px', minWidth: '80%', maxWidth: '85%', textAlign: 'left' }}>
                                        <SeparationBar content={<RewardsInfo />} />
                                        <div className='w-700'>
                                            Rewards:
                                        </div>
                                        {displayLevel.rewards.map((x, i) => {
                                            let displayLevelInArray = levels.find(z => z.id === displayLevel.id)
                                            if (displayLevelInArray) {
                                                let rewardInLevel = displayLevelInArray.rewards.find((p, s) => s === i)
                                                if (bonuses.rewardBoost && rewardInLevel.type === 'exp') {
                                                    return (
                                                        <div key={i} style={{ paddingLeft: '1rem', fontSize: '0.8rem', display: 'flex', flexFlow: 'row wrap', gap: '0.2rem' }}>
                                                            <div style={{ position: 'relative' }}>
                                                                <div className='flex flex-row' style={{ flexFlow: 'row wrap', gap: '0.2rem' }}>
                                                                    EXP:
                                                                    <div style={{ position: 'relative' }}>
                                                                        {rewardInLevel.data.exp}
                                                                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                                                                            <div className='flex flex-row align-center' style={{ height: '100%', width: '100%' }}>
                                                                                <div style={{ width: '100%', height: '2px', background: 'rgb(248, 218, 115)' }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-wrap justify-center'>
                                                                <div style={{ color: "rgb(248, 218, 115)" }}>{`>>`}</div> {parseFloat(((100 + bonuses.rewardBoost) / 100 * rewardInLevel.data.exp).toFixed(10))}
                                                                <div className='flex flex-wrap justify-center' style={{ width: '8px', alignItems: 'baseline' }}>
                                                                    <img style={{ width: '8px' }} src={TickImg} alt="Verified-check" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }

                                            return (
                                                <div key={i} style={{ paddingLeft: '1rem', fontSize: '0.8rem' }}>
                                                    {x}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {displayLevel.bonuses && (
                                    <div className='flex flex-row justify-center' style={{ width: '100%', margin: '0.5rem 0px', gap: '0.5rem' }}>
                                        <div>
                                            <img src={BonusImg} alt='Requirement' style={{ width: isMobile ? '2rem' : '3rem' }} />
                                        </div>
                                        <div style={{ width: '200px', minWidth: '80%', maxWidth: '85%', textAlign: 'left' }}>
                                            <SeparationBar content={<BonusesInfo />} />
                                            <div className='w-700'>
                                                Bonus:
                                            </div>
                                            {displayLevel.bonuses.map((x, i) => {
                                                return (
                                                    <div key={i} style={{ paddingLeft: '1rem', fontSize: '0.8rem' }}>
                                                        {x}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>)}
                            </div>
                            <div className='flex flex-row justify-right' style={{ padding: '0.8rem 0px 0px 0px', gap: '1rem' }}>
                                <ClaimRewards displayLevel={displayLevel} setClaimedReward={setClaimedReward} />
                                <CheckStatus displayLevel={displayLevel} />
                                <CancelMission displayLevel={displayLevel} />
                                <Button disabled={disableBeginJourney} className='fortify__green-btn' onClick={() => beginJourney(publicKey, displayLevel.projectId, choosenNfts.filter(z => z).map(x => x.mintAddress), displayLevel.id, displayLevel.areaId, setDisableBeginJourney, connection, signTransaction, setChoosenNfts, refresh, setDisplaySignModal)}>Begin Journey</Button>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'column' }}>
                            <div>
                                .
                            </div>
                            <div className='flex flex-row justify-center flex-wrap' style={{ gap: '0.5rem' }}>
                                {[0, 1, 2, 3].map((x, i) => {
                                    return (
                                        <NFTSelect key={i} number={x} setChoosenNfts={setChoosenNfts} choosenNft={choosenNfts[x]} choosenNfts={choosenNfts} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const BonusesInfo = () => {
    return (
        <div style={{ padding: '0.2rem 0.5rem' }}>
            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>Bonuses:</div>
            <div>
                Bonuses are automatically applied when you send the required number of Zalez Beachers from any Faction.
                Since the second Bonus requires one additional Zalez from the faction, by reaching Bonus 2 you will get the benefits of BOTH Bonus 1 and Bonus 2 on the Level
            </div>
            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>Factions:</div>
            <div>
                On Zalez Island there are three factions: Flooded, Sacred and Convalescence.
                Each Faction has a set of traits specifically associated to them along with different tiers. When building your squad for the level please refer to the table below to ensure you build a team that meets requirements and gets you the most bonuses.
            </div>

            <div className='flex'>
                <div>

                </div>

                <div style={{ width: '200px', maxWidth: '50vw', border: '2px solid black' }} className='flex flex-column'>
                    <div style={{ fontWeight: '600', padding: '0.1rem 0px', borderBottom: '2px solid black', textAlign: 'center', fontSize: '0.8rem' }}>
                        Flooded Faction
                    </div>
                    <img style={{ width: '100%' }} src={'https://arweave.net/Er9YWjtWZCMwsUW-PugN_0OECprkuZwsE0phoDvTl_o'} alt="flooded" />
                </div>

                <div style={{ width: '200px', maxWidth: '50vw', border: '2px solid black' }} className='flex flex-column'>
                    <div style={{ fontWeight: '600', padding: '0.1rem 0px', borderBottom: '2px solid black', textAlign: 'center', fontSize: '0.8rem' }}>
                        Sacred Faction
                    </div>
                    <img style={{ width: '100%' }} src={'https://arweave.net/xfUQe6bIvAp-TgpoPqRgFG6vjQUOF2YIzSJ6hRqFpW8'} alt="flooded" />
                </div>
                <div style={{ width: '200px', maxWidth: '50vw', border: '2px solid black' }} className='flex flex-column'>
                    <div style={{ fontWeight: '600', padding: '0.1rem 0px', borderBottom: '2px solid black', textAlign: 'center', fontSize: '0.8rem' }}>
                        Convalescence Faction
                    </div>
                    <img style={{ width: '100%' }} src={'https://arweave.net/IPrndMev_wEEdeBAoNWvcLzuuGOUijMQ9xRnHDxj2ms'} alt="flooded" />
                </div>

            </div>
        </div>
    )
}

const RequirementInfo = () => {
    return (
        <div style={{ padding: '0.2rem 0.5rem' }}>
            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>Minimum Experience:</div>
            <div>
                Each Zalez Beachers selected for the level must have the minimum experience specified.
            </div>

            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>$RAYZ Requirement:</div>
            <div>
                The $RAYZ amount specified indicates the required cost to start a level. This cost is the same regardless of the number of Zalez Beachers selected for the level.
            </div>

            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>NFT Requirement:</div>
            <div>
                The NFT requirement indicates both the minimum number of NFTs required for the level along with any special trait requirements.
            </div>

        </div>
    )
}

const RewardsInfo = () => {
    return (
        <div style={{ padding: '0.2rem 0.5rem' }}>
            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>EXP Rewards:</div>
            <div>
                The EXP rewards displayed will be applied to each NFT you send on a mission. Earning EXP is the only way to progress through the Areas and Levels.
            </div>

            <div style={{ fontSize: '1.2rem', fontWeight: '600', fontStyle: 'italic' }}>Expedition Gear Rewards:</div>
            <div>
                There are three types of Expedition Gear: Components, Apparatuses and Expedition Kits. Gear will be required to accomplish some levels and further level up your Beachers. The Gear will also be a requirement to "Set off on an EXPedition"
            </div>
        </div>
    )
}

const ClaimRewards = ({ displayLevel, setClaimedReward }) => {
    const { missions, refresh, signModalState } = useContext(MainContext)
    const [, setDisplaySignModal] = signModalState
    const [disableBtn, setDisableBtn] = useState(false)
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    if (missions && missions.find(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId && z.rewards.length)) {
        return (
            <Button disabled={disableBtn} className='fortify__green-btn' onClick={() => claimRewards(publicKey, displayLevel.projectId, displayLevel.id, displayLevel.areaId, setDisableBtn, setClaimedReward, refresh, connection, signTransaction, setDisplaySignModal)}>Claim Rewards</Button>
        )
    }
}

const CancelMission = ({ displayLevel }) => {
    const [open, setOpen] = useState(false)
    const [disableBtn, setDisableBtn] = useState(false)


    const { missions, project, refresh, signModalState } = useContext(MainContext)
    const [, setDisplaySignModal] = signModalState
    const { publicKey, signTransaction } = useWallet()
    const { connection } = useConnection()
    if (displayLevel && missions && missions.find(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId)) {
        return (
            <>
                {open ? (<div className='modal-wrapper'>
                    <div className='modal-bg' onClick={() => setOpen(false)}></div>
                    <div className='modal-container'>
                        <div className='modal' style={{ padding: '0.5rem 2rem 0.5rem 0.5rem', maxWidth: '400px', width: '80vw', color: 'black' }}>
                            <div style={{ fontSize: '1.3rem', padding: '0px 0px 0px 1rem' }} className='font-marker'>WARNING!</div>
                            <div>
                                This will cancel all the missions sent on this level and all your NFTs will return empty handed.
                                Click on confirm if you want to move forward and cancel all missions on this level.
                            </div>
                            <Button disabled={disableBtn} className='fortify__green-btn' onClick={() => cancelMissions(publicKey, project.projectId, displayLevel.id, displayLevel.areaId, setDisableBtn, connection, signTransaction, refresh, project, setOpen, setDisplaySignModal)}>Confirm</Button>
                        </div>
                    </div>
                </div>) : (<></>)}
                <Button className='fortify__green-btn' style={{ '--bg-color': '#d75454' }} onClick={() => setOpen(true)}>Cancel Missions</Button>

            </>
        )
    }
}
const CheckStatus = ({ displayLevel }) => {
    const [open, setOpen] = useState(false)
    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: '80vw',
            padding: '0.5rem 1rem'
        },
    });

    const { missions } = useContext(MainContext)
    if (missions && missions.find(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId)) {
        return (
            <CustomWidthTooltip title={<div onClick={() => setOpen(!open)}>
                <div style={{ fontSize: '1.4rem', paddingBottom: '0.3rem' }}>
                    Level Status:
                </div>
                <div>
                    Number of Active Missions: {missions.filter(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId).length}
                </div>
                <div>
                    Total Zalez in Level:  {missions.filter(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId).reduce((a, b) => a + b.nfts.length, 0)}
                </div>
                {missions && missions.find(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId && z.endingAt > Date.now()) && (
                    <div>
                        Estimated Time left to complete: {prettyMilliseconds((missions.filter(z => z.levelId === displayLevel.id && z.areaId === displayLevel.areaId && z.endingAt > Date.now()).sort((a, b) => b.endingAt - a.endingAt))[0].endingAt - Date.now(), { verbose: true })}
                    </div>
                )}
            </div>} placement="top" open={open} onClose={() => setOpen(false)} style={{ backgroundColor: '#808181' }} className='fortify__check-statsu__tooltip' arrow>
                <Button onClick={() => setOpen(!open)} className='fortify__green-btn' style={{ '--bg-color': '#5D615C' }}>Check Status</Button>
            </CustomWidthTooltip>
        )
    }
}
const NFTSelect = ({ number, choosenNft, setChoosenNfts, choosenNfts }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { nfts, missions, project, refresh, signModalState } = useContext(MainContext)
    const { publicKey } = useWallet()
    const [, setDisplaySignModal] = signModalState
    const [nftColors, setNftColors] = useState({})
    const [disabledBtn, setDisabledBtn] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <div className='fortify__select-nft' aria-describedby={id} variant="contained" onClick={handleClick}>
                <img src={PlusImg} alt='plus-sign' style={{ width: '40%' }} />
                {choosenNft && (<img src={choosenNft.image} className='fortify__selected-nft' alt={choosenNft.name} />)}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className='fortify__select-nft__container'>
                    <div className='font-marker' style={{ textAlign: 'center', fontSize: '1.3rem' }}>
                        Select your Zalez beachers
                    </div>
                    <div className='fortify__select-nfts-wrapper'>
                        {!publicKey ? 'You need to connect your wallet' : !nfts ? <Preloader /> : !nfts.filter(y => missions && !missions.find(mission => mission.nfts.includes(y.mintAddress.toString()))).length ? `No NFT to choose` : (
                            <div className='flex flex-row flex-wrap justify-center' style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
                                {nfts.filter(y => missions && !missions.find(mission => mission.nfts.includes(y.mintAddress.toString()))).sort((a, b) => b.exp - a.exp).map((x, i) => {
                                    return (
                                        <NFTToSelect setNftColors={setNftColors} i={i} setChoosenNfts={setChoosenNfts} x={x} choosenNft={choosenNft} choosenNfts={choosenNfts} number={number} />
                                    )
                                })
                                }
                            </div>)
                        }
                    </div>
                    {publicKey && (<Button disabled={disabledBtn} className='fortify__green-btn' onClick={() => updateColors(publicKey, project.projectId, refresh, nftColors, setNftColors, disabledBtn, setDisabledBtn, setDisplaySignModal)}>
                        Update Colours
                    </Button>)}

                </div>
            </Popover>
        </div>
    );
}

const NFTToSelect = ({ x, i, setChoosenNfts, choosenNft, number, setNftColors, choosenNfts }) => {
    const [colour, setColour] = useState(x.color)
    useEffect(() => {
        setNftColors((prev) => {
            let newNftColor = JSON.parse(JSON.stringify(prev))
            newNftColor[x.mintAddress] = colour
            return newNftColor
        })
    }, [colour])
    return (
        <div className='fortify__select-nfts__nft-container' style={{ backgroundColor: colour || 'transparent' }}>
            <div className='fortify__select-nfts__nft-exp'>
                <div>
                    EXP: {Math.floor(x.exp || 0)}
                </div>
                <div>
                    Level: {x.level || 0}
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                <input type="color" style={{ height: '15px', width: '30px' }} value={colour} onChange={(event) => setColour(event.target.value)} />
            </div>
            <LazyLoadImage src={x.image} key={i} style={{ borderColor: choosenNft?.mintAddress === x?.mintAddress ? '#00ffff' : 'transparent' }} onClick={() => {
                setChoosenNfts((prev) => {
                    let newValue = [...prev]
                    if (choosenNfts.find((z, i) => z && z.mintAddress === x.mintAddress)) {
                        newValue[newValue.indexOf(newValue.find(z => z && z.mintAddress === x.mintAddress))] = undefined
                    } else {
                        newValue[newValue.indexOf(newValue.find(z => !z))] = x
                    }
                    return newValue
                })
            }} alt={x.name} className='fortify__select-nfts__nft' />
        </div>)

}


const SeparationBar = ({ content }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const CustomWidthTooltip = ({ children }) => {
        return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                style={{ background: 'transparent' }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {children}
            </Popover>
        )
    }

    return (
        <div style={{ width: '95%', height: '2px', position: 'relative', background: '#F8DA73' }}>
            <div style={{ display: 'flex', width: '30px', height: '30px', position: 'absolute', background: '#F8DA73', right: '-15px', top: '-15px', transform: 'rotate(46deg)' }}>
                <img onClick={(event) => handleClick(event)} src={InfoIcon} alt="info" style={{ width: '1.5rem', margin: 'auto', transform: 'rotate(-45deg)', cursor: 'pointer' }} />

                <CustomWidthTooltip >
                    <div style={{ width: '700px', maxWidth: '90vw', background: '#f8cf3dd9', border: '2px solid black' }}>
                        {content}

                    </div>
                </CustomWidthTooltip>

            </div>
        </div>
    )
}
