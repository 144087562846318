import React, { useContext, useEffect } from 'react'
import { useCheckMobileScreen } from '../javascript/utility'
import { MainContext } from '../App'
import TopUp from '../components/expeditions/TopUp'
import './../css/expedition.css'
import { getAssociatedTokenAddress } from '@solana/spl-token'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import ExpList from '../components/expeditions/ExpList'
import Dashboard from './Dashboard'
import { Link } from 'react-router-dom'

export default function Expedition() {
    const { project, navbarTitle, expGearsState } = useContext(MainContext)
    const { connection } = useConnection()
    const { publicKey } = useWallet()
    const [, setExpGears] = expGearsState
    const [, setNavbarTitle] = navbarTitle
    const isMobile = useCheckMobileScreen()
    useEffect(() => {
        const checkBalance = async () => {
            let newExpGears = []
            for (const gear of project.expeditionGears) {
                let balance = 0
                let sourceAccount = await getAssociatedTokenAddress(
                    new PublicKey(gear.data.tokenAddress),
                    publicKey
                );
                const sourceAccountData = (await connection.getParsedAccountInfo(sourceAccount))
                let newBalance = sourceAccountData.value?.data?.parsed.info?.tokenAmount?.amount
                if (Number(newBalance)) balance = newBalance
                newExpGears.push({
                    ...gear,
                    balance: parseFloat((balance / gear.data.decimals).toFixed(10))
                })
            }
            setExpGears(newExpGears)
        }
        checkBalance()
    }, [project.expeditionGears, publicKey, connection, setExpGears])

    useEffect(() => {
        setNavbarTitle('Set off on an EXPedition')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='home__content-wrapper' style={{ margin: isMobile ? '0.2rem 1rem' : '0.2rem 1rem', backgroundImage: `url(${project.images.expeditionBackground})` }}>
            <div className='home__content' style={{ padding: isMobile ? '0px 0.5rem' : '0px 2rem' }}>
                <div className='flex flex-column' style={{ gap: '1.5rem' }}>
                    {project.ownerAddress === publicKey?.toString() ? (
                        <div className='utility__info-box__yellow utility__info-box__yellow-no-hover exp__yellow-container' style={{ height: 'fit-content', borderRadius: '20px', marginTop: '1rem' }}>
                            <Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
                                <div className='flex flex-column font-marker' style={{ fontSize: '2rem', color: 'black' }}>
                                    Dashboard
                                </div>
                            </Link>
                        </div>
                    ) : (<></>)}
                    <TopUp />
                    <ExpList />
                </div>
            </div>
        </div>
    )
}
