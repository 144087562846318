import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './../../css/fortify.css'
import { useCheckMobileScreen } from '../../javascript/utility'
import { MainContext } from '../../App'
import Level from './Level'
import { updatedLevelFormat } from '../../javascript/fortify'
export default function Area() {
    const params = useParams()
    const { navbarTitle, areas, levels } = useContext(MainContext)
    const [, setNavbarTitle] = navbarTitle
    const isMobile = useCheckMobileScreen()
    const [activeArea, setActiveArea] = useState()
    const [displayLevel, setDisplayLevel] = useState()
    const [areaLevels, setAreaLevels] = useState()
    useEffect(() => {
        const newArea = areas.find(x => x.id === params.areaId)
        if (newArea) {
            setActiveArea(newArea)
            setNavbarTitle(newArea.label)
            // eslint-disable-next-line react-hooks/exhaustive-deps    
        }
        const newLevels = levels.filter(x => x.areaId === params.areaId)
        if (newLevels) setAreaLevels(newLevels)
    }, [params, setNavbarTitle, levels, areas])
    if (activeArea && areaLevels) {
        return (
            <div className='home__content-wrapper' style={{ margin: '0.2rem 1rem', background: `url(${activeArea.data?.images?.background})` }}>
                <Level displayLevel={displayLevel} setDisplayLevel={setDisplayLevel}/>
                <div className='home__content' style={{ padding: isMobile ? '1rem 0px' : '1rem 2rem' }}>
                    <div className='utility__info-box__yellow utility__info-box__cyan' style={{ minHeight: '40px', margin: isMobile ? '0px 1rem' : 'inherit' }}>
                        <div className='home__anchor-container'>
                            <div>
                                {activeArea.description}
                            </div>
                        </div>
                    </div>
                
                    <div className='fortify__area__levels-list'>
                        {areaLevels.length ? areaLevels.sort((a, b) => a.createdAt-b.createdAt).map((x, i) => {
                            return (
                                <div key={i} className='fortify__area__levels-list__level-wrapper' onClick={() => setDisplayLevel(updatedLevelFormat(x))} style={{ background: `url(${x.data?.images?.background})`, width: isMobile ? '40%' : '45%' }}>
                                    <div className='font-marker fortify__area__levels-list__level-wrapper__label' style={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
                                        {x.label}
                                    </div>
                                </div>
                            )
                        }) : (
                            <div style={{
                                fontSize: '1.5rem',
                                maxWidth: '70vw',
                                fontWeight: '500',
                                textTransform: 'uppercase'
                            }}>
                                There is no level in this area
                            </div>
                        )}
                    </div>
                    <Link to={'/fortify'} className='utility__info-box__yellow'>
                        <div className='home__anchor-container'>
                            <div className='utility__info-box__yellow-title font-marker'>
                                Return to Area Select
                            </div>
                            <div>
                                Choose another Area to Explore
                            </div>
                        </div>
                    </Link>

                </div>
            </div>
        )
    }
}
